import React, { useState, useEffect } from "react";
import { Box, DateInput, Grommet, Button, TextInput, Table, TableHeader, TableBody, TableRow, TableCell } from 'grommet';
import { Edit, Checkmark, FormClose, Trash } from "grommet-icons";
import firebase from '../firebase';


export default function AnagraficheManutenzioniEseguite({ eseguito ,ruolo}) {

const [modify, setModify] = useState(false);
const [eseguite, setEseguite] = useState([]);
const [codice, setCodice] = useState("");
const [Data, setData] = useState("1900-01-01");
const [Telaio, setTelaio] = useState("");
const [Modello, setModello] = useState("");
const [OreMacchina, setOreMacchina] = useState("");

  const onUpdateCodice = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').doc(eseguito.id).set({ ... eseguito, codice});
    setModify(false);
  }

  const onUpdateTelaio = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').doc(eseguito.id).set({ ... eseguito, Telaio});
    setModify(false);
  }

  const onUpdateData = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').doc(eseguito.id).set({ ... eseguito, Data});
    setModify(false);
  }

  const onUpdateModello = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').doc(eseguito.id).set({ ... eseguito, Modello});
    setModify(false);
  }

  const onUpdateOreMacchina = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').doc(eseguito.id).set({ ... eseguito, OreMacchina});
    setModify(false);
  }

  const onDelete = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').doc(eseguito.id).delete();
    setModify(false);
  }

  function dataTrasformation(data){
  	if(data== null){
  		return null;
  	}
  	else{
  		var dataArray= data.split('-');
    	var newData=dataArray[2]+'-'+dataArray[1]+'-'+dataArray[0];
    	return newData;
  	}        
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]"));

return (
<>
	<TableRow id={eseguite.id}>
		<TableCell scope="row">
			{ modify ?
				<>
				<TextInput
				    placeholder={eseguito.CodiceManutenzione}
				    onChange={e => setCodice(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateCodice} label="" />
				</>
				:
				<>
				<strong>{eseguito.CodiceManutenzione}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<DateInput
          format="dd/mm/yyyy"
          value={(new Date()).toISOString()}
          onChange={({ value }) => {setData(value.split('T')[0])}}
          calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
        />
				<Button icon=<Checkmark /> onClick={onUpdateData} label="" />
				</>
				:
				<>
				<strong>{dataTrasformation(eseguito.Data)}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={eseguito.Telaio}
				    onChange={e => setTelaio(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateTelaio} label="" />
				</>
				:
				<>
				<strong>{eseguito.nomeMacchina}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={eseguito.OreMacchina}
				    onChange={e => setOreMacchina(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateOreMacchina} label="" />
				</>
				:
				<>
				<strong>{eseguito.OreMacchina}</strong>
				</>
			}
		</TableCell>
		{ ruolo == 'Admin' ?
		<TableCell>
			{ modify ?
				<>
				<Button icon=<FormClose /> onClick={() => setModify(false)} label="" />
				</>
				:
				<>
				<Button icon=<Edit /> onClick={() => setModify(true)} label="" />
				</>
			}
		</TableCell>
		:
		null
		}
	</TableRow>
</>
)

}
import React, { useState, useEffect } from "react";
import { Box, Grommet, Button, TextInput, Table, TableHeader, TableBody, TableRow, TableCell, DateInput, Select } from 'grommet';
import { Edit, Checkmark, FormClose, Trash, Map as Detail } from "grommet-icons";
import firebase from '../firebase';
import moment from 'moment';
//import { Badge,ChakraProvider,Box as BoxChakra  } from '@chakra-ui/react'
import { Chip } from "@material-ui/core";

export default function AnagraficheAtt({ attivita ,ruolo}) {

const [modify, setModify] = useState(false);

const dip = firebase.firestore().collection('Dipendenti');
const [Dipendente, setDropdownDipSel] = useState("");
const [Matricola,setMatricola]=React.useState("");
const [dipendenti, setDipendenti] = useState([]);

const mac = firebase.firestore().collection('Macchinari');
const [macchine, setMacchinari] = useState([]);
const [Macchina, setDropdownMacSel] = useState('');
const [Telaio,setTelaio]=React.useState("");

const lav = firebase.firestore().collection('Lavori');
const [lavori, setLavori] = useState([]);
const [Lavoro, setDropdownLavSel] = useState('');

const prod = firebase.firestore().collection('Prodotti');
const [prodotti, setProdotti] = useState([]);
const [Prodotto, setDropdownProdSel] = useState('');
const [Sku,setSku]=React.useState("");

const aree = firebase.firestore().collection('Aree');
const [Zona, setDropdownZonaSel] = useState('');
const [zona, setZona] = useState([]);

const [Ordinaria, setOrdinaria] = useState("");

const [Data, setData] = useState("");

const [Messaggio, setMessaggio] = useState("");

const [Durata, setDurataAtt] = useState("");

const [CostoMacchina, setCostoMacchina] = useState("");

const [CostoDipendente, setCostoDipendente] = useState("");
	
const [user,setUser]=useState("");

/**
 * Funzione che trova il nome e il cognome del dipendente che ha svolto l'attività
 */
function getNomeCognome(){
	dip.where("Matricola","==",attivita.Matricola).onSnapshot((querySnapshot)=>{
		var userLoggedApp;
		querySnapshot.forEach((doc)=>{
		  userLoggedApp=doc.data().Nome+" "+doc.data().Cognome;
		})
		setUser(userLoggedApp);
	  })
}

/**
 * Funzione che rileva tutti i dipendenti presenti a sistema
 */
  function getDipendenti() {

        dip.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setDipendenti(items);
        });
  }

  /*
  	Funzione che aggiorna il dipendente che ha svolta l'attività
  */
  const onUpdateDip = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Dipendente,Matricola});
    setModify(false);
  }

/**
 * Funzione che rileva tutti i macchinari presenti a sistema
 */
  function getMacchinari() {

        mac.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setMacchinari(items);
        });

  }

  /*
  	Funzione che aggiorna il macchinario con cui è stata svolta l'attività
  */
  const onUpdateMac = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Macchina,Telaio});
    setModify(false);
  }

  /**
 * Funzione che rileva tutti i prodotti presenti a sistema
 */
  function getProdotti() {

        prod.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);
        });

  }

  /*
  	Funzione che aggiorna il prodotto con cui è stata svolta l'attività
  */
  const onUpdateProd = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Prodotto,Sku});
    setModify(false);
  }

/**
 * Funzione che rileva tutti i lavori presenti a sistema
 */
  function getLavori() {

        lav.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setLavori(items);
        });

  }

  /*
  	Funzione che aggiorna il lavoro  dell'attività
  */
  const onUpdateLav = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Lavoro});
    setModify(false);
  }

/**
 * Funzione che rileva tutti i cantieri/zone/adl presenti a sistema
 */
  function getAree() {

        aree.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setZona(items);
        });

  }

  /*
  	Funzione che aggiorna adl dell'attività
  */
  const onUpdateZona = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Zona});
    setModify(false);
  }

  /*
  	Funzione che aggiorna la data dell'attività
  */
  const onUpdateData = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Data});
    setModify(false);
  }

   /*
  	Funzione che aggiorna il messaggio dell'attività
  */
  const onUpdateMess = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Messaggio});
    setModify(false);
  }

  /*
  	Funzione che aggiorna la durata dell'attività
  */
  const onUpdateDurata = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Durata});
    setModify(false);
  }

  /*
  	Funzione che aggiorna la tipologia dell'attività
  */
  const onUpdateOrdinaria = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).set({ ... attivita, Ordinaria});
    setModify(false);
  }

  /*
  	Funzione che elimina un attività
  */
  const onDelete = () => {
    const db = firebase.firestore();
    db.collection('Attivita').doc(attivita.id).delete();
    setModify(false);
  }

  /**
   * Funzione che ritorna la data in formato dd-MM-yyyy
   */
  function dataTrasformation(data){
    var dataArray= data.split('-');
    var newData=dataArray[2]+'-'+dataArray[1]+'-'+dataArray[0];
    return newData;    
  }
  
  /**
   * Funzione che calcola l'indice di performance dell'attività 
   */
  function getPerformance(stimata,effettiva){
  		if(stimata==0){
  			return 0;
  		}
  		else{
  			if(effettiva<stimata){
  				var preformance= -(1-(effettiva/stimata))*100;
  			}
  			if(effettiva>stimata){
  				var preformance= +(effettiva-stimata)/stimata*100;
  			}

  			return Math.round(preformance*100)/100 + "%";
  		}
  }

  /**
   * Funzione che in base alla matricola del dipendente che svolge l'attività rileva i costi
   */
  function getCostoDipendente(){
  	dip.where('Matricola', '==', attivita.Matricola).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        if(items.length !== 0){
        	if(items[0].CostoInt == "" ){
        	setCostoDipendente(0);
	        } else{
	      		setCostoDipendente(items[0].CostoInt);
	      	} 
        }else{
	      		setCostoMacchina(0);
	      }   
      });
	}
	
  /**
   * Funzione che in base al telaio della macchina usata per l'attività rileva i costi del macchinario
   */
  function getCostoMacchinario(){
    if(attivita.Telaio!=undefined){
        mac.where('Telaio', '==', attivita.Telaio).onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
              items.push( { ... doc.data(), id: doc.id } );
            });
            if(items.length !== 0) {

                if(items[0].CostoOrario == "" ){
               		setCostoMacchina(0);
                } else {
                	setCostoMacchina(items[0].CostoOrario);
                }
                
            } else {
            	setCostoMacchina(0);
            }
        });
    }

  }

  /**
   * Funzione che ritorna il costo dell'attività
   */
  function getCosti(dipendete,macchinario,ore){
  	var costototale= Math.round((dipendete*ore+macchinario*ore)*100)/100;
  	return costototale+"€";
  }

   const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]"));
	
   	const elencoMatricole=[];
	const elencoMacchinari=[];
	const elencoSku=[];

	/**
	 * Funzioni che impostano le chavi univoce delle singole anagrafiche selezionate
	 */
	function onChangedSetDip(option){
		setMatricola(elencoMatricole[option.selected]);
		setDropdownDipSel(option.value);
	}
	
	function onChangedSetMac(option){
		setTelaio(elencoMacchinari[option.selected]);
		setDropdownMacSel(option.value);
	}
	
	function onChangedSetSku(option){
		setSku(elencoSku[option.selected]);
		setDropdownProdSel(option.value);
	}
	

  useEffect(() => {
    getDipendenti();
    getMacchinari();
    getLavori();
    getProdotti();
    getAree();
    getCostoDipendente();
    getCostoMacchinario();
  }, []);

  useEffect(()=>{
	getNomeCognome();
  },[attivita])

  /**
   * Funzione che trasforma la durata della attività in hh:mm
   */
  function durataTrasform(durata){
    if(durata>59){
      var oraT=durata/60;
      var ora=parseInt(oraT);
      var minuti=(oraT-ora)*60;
      var durataF=ora+'h '+Math.round(minuti)+'m';
    }
    else{
      var durataF=Math.round(durata)+'m'
    }

    return durataF;
  }


return (
<>
	<TableRow id={attivita.id} style={{color:'black'}}>
		{/*<TableCell scope="row">
			<strong>{attivita.Approvata == 'si' ? "Terminata" : (attivita.Approvata == 'partita' ? "In corso" : (attivita.Approvata == 'manuale' ? "Chiusa Manualmente" : "Pending"))}</strong>
		</TableCell>*/}
		<TableCell scope="row">
			<Chip style={{backgroundColor: attivita.Approvata=="si"?"#66bb6a":attivita.Approvata=="manuale"?"#0277bd":attivita.Approvata=="confermata"?"#bdbdbd":attivita.Approvata=="partita"?"#ffeb3b":attivita.Approvata == 'chiusura'?"#f06292":attivita.Approvata==""?"#bdbdbd":""}}
				label={attivita.Approvata=="si"?"Terminata":attivita.Approvata=="manuale"?"Manuale":attivita.Approvata=="confermata"?"Pending":attivita.Approvata=="partita"?"In corso":attivita.Approvata == 'chiusura'?"Chiusura Giornaliera":attivita.Approvata==""?"Pending":""}
			/>
		</TableCell>
		<TableCell scope="row">
			{ modify ?
				<>
				<Select
	              options={(dipendenti.filter(   
					function(a){
					  if(elencoMatricole.indexOf(a.Matricola)===-1){
						  elencoMatricole.push(a.Matricola);
						  return true;
						}
						return false;
					  
				  }).map((item)=>item.Nome+" "+item.Cognome))}
	              placeholder={user}
	              value={Dipendente}
	              onChange={( option ) => onChangedSetDip(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateDip} label="" />
				</>
				:
				<>
				<strong>{user}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={macchine.filter(   
					function(a){
					  if(elencoMacchinari.indexOf(a.Telaio)===-1){
						  elencoMacchinari.push(a.Telaio);
						  return true;
						}
						return false;
					  
				  }).map((item)=>item.NomeMacchina)}
	              placeholder={attivita.Macchina}
	              value={Macchina}
	              onChange={( option ) => onChangedSetMac(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateMac} label="" />
				</>
				:
				<>
				<strong>{attivita.Macchina}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={lavori.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(lavoro => (lavoro.Nome))}
	              placeholder={attivita.Lavoro}
	              value={Lavoro}
	              onChange={({ option }) => setDropdownLavSel(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateLav} label="" />
				</>
				:
				<>
				<strong>{attivita.Lavoro}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={zona.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(area => (area.Nome+'-'+area.Localita))}
	              placeholder={attivita.Zona}
	              value={Zona}
	              onChange={({ option }) => setDropdownZonaSel(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateZona} label="" />
				</>
				:
				<>
				<strong>{attivita.Zona}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<Select
	              options={prodotti.filter(   
					function(a){
					  if(elencoSku.indexOf(a.Sku)===-1){
						elencoSku.push(a.Sku);
						return true;
						}
						return false;
					  }
				  ).map((item)=>item.Nome)}
	              placeholder={attivita.Prodotto}
	              value={Prodotto}
	              onChange={( option ) => onChangedSetSku(option)}
	            />
				<Button icon=<Checkmark /> onClick={onUpdateProd} label="" />
				</>
				:
				<>
				<strong>{attivita.Prodotto}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<DateInput
                	format="dd/mm/yyyy"
                	value={(new Date()).toISOString()}
                	onChange={({ value }) => {setData(value.split('T')[0])}}
                	calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
              	/>
				<Button icon=<Checkmark /> onClick={onUpdateData} label="" />
				</>
				:
				<>
				<strong>{dataTrasformation(attivita.Data)}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? moment.parseZone(new Date(attivita.Start)).local().format('DD-MM-YYYY') : (attivita.Approvata == 'chiusura' ? "Chiusura Giornata" : "Attività non iniziata")}</strong>
		</TableCell>
		{/*<TableCell>
			<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? moment.parseZone(new Date(attivita.End)).local().format('YYYY-MM-DD HH:mm:ss') : "Attività non iniziata"}</strong>
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.Messaggio}
				    onChange={e => setMessaggio(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateMess} label="" />
				</>
				:
				<>
				<strong>{attivita.Messaggio}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.MessaggioLav}
				    onChange={e => setMessaggioLav(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateMessLav} label="" />
				</>
				:
				<>
				<strong>{attivita.MessaggioLav}</strong>
				</>
			}
		</TableCell>*/}
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.Durata}
				    onChange={e => setDurataAtt(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateDurata} label="" />
				</>
				:
				<>
				<strong>{attivita.Approvata==="chiusura"?durataTrasform(attivita.Durata*60):durataTrasform(attivita.Durata)}</strong>
				</>
			}
		</TableCell>
		<TableCell>
			<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? durataTrasform(Number(parseFloat(((moment(Date.parse(attivita.End)).set('second',0) - moment(Date.parse(attivita.Start)).set('second',0))/1000/60), 10).toFixed(2))) : 0}</strong>
		</TableCell>
		{/*TK-88 Admin - Calcolo performance in base alla durata stimata  - START*/}
		{ ruolo == 'Admin' ?
		<>
			<TableCell>
				<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? getPerformance(attivita.Durata , Number(parseFloat(((Date.parse(attivita.End) - Date.parse(attivita.Start))/1000/60), 10).toFixed(0))) : 0}
				</strong>
			</TableCell>
			{/*TK-88 Admin - Calcolo performance in base alla durata stimata  - END*/}
			{/*TK-87 Admin - Calcolo costi associati alle attività - START*/}
			<TableCell>
				<strong>{attivita.Approvata == 'si' || attivita.Approvata == 'manuale' ? getCosti(CostoDipendente,CostoMacchina,Number(parseFloat(((Date.parse(attivita.End) - Date.parse(attivita.Start))/1000/60), 10).toFixed(0))/60) : 0}</strong>
			</TableCell>
			{/*TK-87 Admin - Calcolo costi associati alle attività - END*/}
		</>
		:
			null
		}
		<TableCell>
			{ modify ?
				<>
				<TextInput
				    placeholder={attivita.Ordinaria}
				    onChange={e => setOrdinaria(e.target.value)}
				/>
				<Button icon=<Checkmark /> onClick={onUpdateOrdinaria} label="" />
				</>
				:
				<>
				<strong>{attivita.Ordinaria}</strong>
				</>
			}
		</TableCell>
		{ ruolo == 'Admin' ?
		<TableCell>
			{ modify ?
				<>
				<Button icon=<FormClose color="black"/> onClick={() => setModify(false)} label="" />
				<Button icon=<Trash color= "black"/> onClick={onDelete} label="" />
				</>
				:
				(attivita.Approvata == 'si' || attivita.Approvata == 'partita' ?
				<Button icon=<Detail color="black"/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+attivita.id} label="" />
				:
				<>
				<Button icon=<Edit color="black"/> onClick={() => setModify(true)} label="" />
				<Button icon=<Detail color="black"/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+attivita.id} label="" />
				</>
				)
			}
		</TableCell>
		:
		<TableCell>
			<Button icon=<Detail color= "black"/> onClick={() => window.location.href = window.location.origin + "/dettaglio/"+attivita.id} label="" />
		</TableCell>
		}
	</TableRow>
</>
)

}
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase';

// Add the Firebase products that you want to use
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q",
  authDomain: "pelizzari-env.firebaseapp.com",
  projectId: "pelizzari-env",
  storageBucket: "pelizzari-env.appspot.com",
  messagingSenderId: "1093694075399",
  appId: "1:1093694075399:web:4aa186a201549be07430a2"
};



// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;
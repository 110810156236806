import React, {useState, useEffect} from "react";
import { Grommet,ResponsiveContext,DropButton, Notification as NotificationApp,Button,Image,Box,Anchor,Collapsible,Text,Select,TextInput,Layer} from "grommet";
import { Notification,LinkPrevious, UserWorker, MapLocation, Analytics, Task, Configure, Location, Close } from "grommet-icons";
import firebase from '../firebase';
import  { Redirect ,useHistory} from 'react-router-dom';
import Notifications from "./Notifications";
import { GoogleMap, useJsApiLoader, DrawingManager, Polygon } from '@react-google-maps/api';
import TextFieldLabel from "./TextInputLabel";
import SideBar from "./SideBar";
import SideBarMobile from "./SideBarMobile";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};
const Mappa = (props) => {

const [color, setColor] = React.useState('#ffff00');
const [colorSelected, setColorSelected] = React.useState('');
const colors = [
  {key: 'Verde', value: '#009900'},
  {key: 'Giallo', value: '#eed000'},
  {key: 'Blu', value: '#0066ff'},
  {key: 'Rosso', value: '#cc3300'},
  {key: 'Viola', value: '#800080'},
  {key: 'Porpora', value: '#ee82ee'},
  {key: 'Marrone', value: '#a52a2a'},
  {key: 'Nero', value: '#000000'},
  {key: 'Oro', value: '#ffd700'},
  {key: 'Azzurro', value: '#add8e6'},
  {key: 'Verde Chiaro', value: '#90ee90'},
  {key: 'Rosa', value: '#ffc0cb'},
  {key: 'Grigio', value: '#808080'},
  {key: 'Arancione', value: '#ffa500'},
  {key: 'Verde Acqua', value: '#00cc99'},
  {key: 'Verde Erba', value: '#99cc00'},
  {key: 'Argento', value: '#c0c0c0'},
  {key: 'Giallo Ocra', value: '#aea04b'},
  {key: 'Bronzo', value: '#cd7f32'},
  {key: 'Beige', value: '#f5f5dc'},
];

const [tipo, setTipo] = React.useState('');
const tipologie = ["Green", "Aprons", "Rough", "Campo Pratica","Tee", "Bunker", "Fairway", "Collar", "Altro"];

const [adl, setAdl] = React.useState('');
const [zona, setZona] = React.useState('');
const [cantiere, setCant] = React.useState('');

const cant = firebase.firestore().collection('Aree');
const [cantieri, setCantieri] = React.useState([]);
const [zone, setZone] = React.useState([]);

const buc = firebase.firestore().collection('Coordinate');
const [buche, setBuche] = useState([]);
const [homeDef, setHomeDef] = useState([]);
const [poligoni, setPoligoni] = useState([]);
const [polygonRef, setPolygonRef] = useState([]);
const [mostraLayer,setMostraLayer]=React.useState(props.match===undefined?true:false);
const [mostraLayerManual,setMostraLayerManual]=React.useState(false);
let labelBuca="";
const [showSideBarMob,setShowSideBarMob]=React.useState(false);
const [landScapeError,setLandScapeError]=React.useState(false);
  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const role = firebase.firestore().collection('Ruoli');
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/

  /*CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/
  const notify = firebase.firestore().collection('Notifiche');
  const [notifiche, setNotifiche] = useState([]);
  /*END CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/

/** 
 * @param {*} event evento passato da MapWithADirectionsRenderer che contiene latitutine e longitudine del punto scelto
 * 
 * Rileva e latitudine della sorgente e della destinazione, le salva e chiama la funzione road() che calcola il percorso
 */


 var allShapes = []; //Contiene tutti i poligoni tracciati sulla mappa
 var selectedShape; //Poligono selezionato 
 
  React.useEffect(()=>{
    if(!mostraLayerManual){
      if(cantiere!=="" && zona!=="" && adl!=="" && tipo!=="" && colorSelected!==""){
        setMostraLayer(false);
      }
    }
  },[cantiere,zona,adl,tipo,colorSelected])

 /**
  * Funzione che ottiene il tracciato del documento passato per props e setta le configurazioni
  * Viene lanciata solo se siamo in modalità modifica di un tracciato
  */
 function getPoligoni() {
        buc.where(firebase.firestore.FieldPath.documentId(), '==', props.match.params.id).onSnapshot((querySnapshot) => {
          const items = [];
          const listaPoly = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setCant(items[0].Cantiere);
          setAdl(items[0].Nome);
          setZona(items[0].ZonaFilter);
          setColor(items[0].Colore);
          setTipo(items[0].Tipologia);

          for (var i = 0; i < items[0].Coords.length; i++)
          {
            items.map(coordinate => (
              listaPoly.push({ percorso: coordinate.Coords[i].percorso, colore: coordinate.Colore, Index: i ,label:coordinate.Coords[i].label})
            ));
          }
         
          setPoligoni(listaPoly);
        });
      } 

    /**
     * Funzione che permette il salvataggio dei tracciati distingunedo due casi:
     * props.match==undefined: Non stiamo modificando un tracciato quindi aggiungo un nuovo documento
     * props.mtch!=undefined: Stiamo modificando un tracciato, aggiorno l'array e il documento
     */
  function handleSave(){
    if(props.match==undefined){
      var coords = [];
      for(var j=0;j<allShapes.length;j++){ //Prendo il path di ogni poligono
        var path = allShapes[j].getPath();
        var coordsPoligono=[];
        for (var i = 0; i < path.length; i++) { //Per ogni path prendo le coordinate di ogni punto
          coordsPoligono.push({
            lat: path.getAt(i).lat(),
            lng: path.getAt(i).lng()
          });
        }
        coords.push({percorso:coordsPoligono,label:""}); //In percorso c'è un array contente le coordinate in formato lat e lng di ogni singolo poligono, l'array è un array di poligoni
      }
      
      const db=firebase.firestore(); 
        db.collection('Coordinate').add({ Buca: adl, Coords: coords, Nome: adl, Cantiere: cantiere, ZonaFilter: zona, Colore: color, Tipologia: tipo, Tracciato: 'si' }).then((doc) => {
          window.location.href = window.location.origin + "/modificaTraccia/" +doc.id;
      });
    }else{
        var coordsPoligono=[];
        for(var j=0;j<allShapes.length;j++){ //Prendo il path di ogni poligono
          var path = allShapes[j].getPath();
          var singlePoligon=[];
          for (var i = 0; i < path.length; i++) { //Per ogni path prendo le coordinate di ogni punto
            singlePoligon.push({
              lat: path.getAt(i).lat(),
              lng: path.getAt(i).lng()
            });
          }
          coordsPoligono.push({percorso:singlePoligon,label: poligoni[j] == undefined? "":poligoni[j].label}); //In percorso c'è un array contente le coordinate in formato lat e lng di ogni singolo poligono, l'array è un array di poligoni
        } 
        
        var coordPoligSel=[];
        var path =selectedShape.getPath();
        var pathPoligSet=[];
        for (var i = 0; i < path.length; i++) { //Per ogni path prendo le coordinate di ogni punto
          pathPoligSet.push({
            lat: path.getAt(i).lat(),
            lng: path.getAt(i).lng()
          });
        }
        coordPoligSel.push({percorso:pathPoligSet,});
      
        
        for(var i=0;i<coordsPoligono.length;i++){
          if(coordsPoligono[i].percorso[0].lat==coordPoligSel[0].percorso[0].lat && coordsPoligono[i].percorso[0].lng==coordPoligSel[0].percorso[0].lng){
            
            coordsPoligono.splice(i,1,{percorso: coordsPoligono[i].percorso, label:labelBuca});
            break;
          }
        }
        
        const db=firebase.firestore();
        db.collection('Coordinate').doc(props.match.params.id).update({ Buca: adl, Coords: coordsPoligono, Nome: adl, Cantiere: cantiere, ZonaFilter: zona, Colore: color, Tipologia: tipo, Tracciato: 'si' }).then((res)=> window.location.reload());
    }  
  }

  /**
   * Funzione che rileva il ruolo dell'utente loggato
   */
  function getRuoli() {
    role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      setRuoli(items);
      
    });
  }

  /**
   * Funzione che rileva tutti i tracciati presenti a sistema
   */
  function getBuche() {

        buc.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setBuche(items);
        
        });
  }

  /**
   * Funzione che rileva tutti i cantieri a sistema
   */
  function getCant() {
    cant.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setCantieri(items);
   
    });
  }

/**
   * Funzione che rileva tutti le zone a sistema in base la cantiere selezionato
   */
  function getZone() {
    cant.where('Nome', '==', cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setZone(items);
      
      });
  }

  /**
   * Funzione che rileva la latitudine e la longitudine in base alle configurazionis scelte con le quali
   * verrà inizializzata la mappa
   */
  function setHome() {
    cant.where('Nome', '==', cantiere).where('Zona', '==', zona).where('Localita', '==', adl).onSnapshot((querySnapshot) => {
      const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
      
        setHomeDef(items[0].Home[0]);
       
    });
  }

  /**
   * Funzione che cancella il poligono selezionato
   */
  function deleteSelectedShape() {
    if (!selectedShape) {
      alert("Non ci sono poligoni selezionati");
      return;
    }

    if (window.confirm("Sei sicuro di voler eliminare il poligono selezionato?")) {
      var index = allShapes.indexOf(selectedShape);
      allShapes.splice(index, 1);
      selectedShape.setMap(null);
      
    }else{
      alert("Operazione annulata")
    }
  }

  /**
   * Componente che implementa il Drawing Manager
   */
const MapWithADirectionsRenderer =()=>{
  
  const [libraries] = useState(['drawing']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: "AIzaSyB9_PMSiHZgKMAgg1dtD-DtdrsfszcC-hg"
  })

  const onMapLoad = map => {};


  /**
   * Annulla la selezione dei poligoni
   */
  function clearSelection() {
    if (selectedShape) {
      selectedShape.setEditable(false);
      selectedShape = null;
    }
  }

  
  /**
   * Cambia poligono selezionato 
   */
  function setSelection(shape) {
    clearSelection();
    selectedShape = shape;
    //labelList.push(labelBuca);
    shape.setEditable(true); 
  }

  /**
   * Funzione che inizializza i listener che permetton l'aggiornamento dell'array contente
   * i nuovi tracciati
   */
  const onLoad = polygon => {
    var newShape = polygon;
    polygonRef.push(polygon.getPath().getArray());
    window.google.maps.event.addListener(polygon, 'click', function() {
      setSelection(newShape);   
      window.google.maps.event.addListener(polygon.getPath(), 'insert_at', function() {
        
      });
      window.google.maps.event.addListener(polygon.getPath(), 'set_at', function() {       
        
      });
    });
    allShapes.push(polygon);
    setSelection(newShape);
}


  return isLoaded?(
    <GoogleMap
    center={new window.google.maps.LatLng(homeDef.lat, homeDef.lng)}
    zoom={16}
    onLoad={map => onMapLoad(map)}
    mapContainerStyle={{ height: "500px", width: "1000px" }}
  >
    <DrawingManager
      options={{
        drawingControl: true,
        drawingControlOptions: {
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
        },
        polygonOptions: {
          fillColor: color,
          fillOpacity: 0.2,
          strokeWeight: 2,
          strokeColor: color,
          clickable: true,
          editable: true,
          geodesic: false,
          visible: true,
          zIndex: 1
        }
      }}
      onOverlayComplete={(poly)=>{
        var newShape=poly.overlay;
        window.google.maps.event.addListener(newShape, 'click', function() {
          setSelection(newShape);
        });
        window.google.maps.event.addListener(newShape.getPath(), 'insert_at', function() {
          
        }); 
        window.google.maps.event.addListener(newShape.getPath(), 'set_at', function() {       
          
        });
        allShapes.push(newShape);
        setSelection(newShape);
      }
    }
    />
          { props.match !== undefined?
            poligoni.map(coordinata=>(      
              <Polygon
                key={coordinata.Index}
                onLoad={onLoad}
                paths= {coordinata.percorso}
                editable={true}
                zIndex={coordinata.Index}
                strokeColor= {coordinata.colore}
                strokeOpacity={0.8}
                strokeWeight={2}
                fillColor= {coordinata.colore}
                fillOpacity={0.35}
                >
              </Polygon>    
              )
            )
            :
            null
          }
  </GoogleMap>
  ):<></>}

  useEffect(() => {
    getBuche();
    getRuoli();
    getCant();
    if (props.match !== undefined) 
    {
      getPoligoni();
    }
  }, []);

  //Gestisce il filtro per le configurazioni
  useEffect(() => {
    getZone();
  }, [cantiere]);

  //Gestisce il filtro per le configurazioni
  useEffect(() => {
    if (cantiere !== '' && zona !== '' && adl !== '') {
      setHome();
    }
  }, [cantiere, zona, adl]);

  
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
   /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */ 
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])
  
  if (sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]"));

  const handleLabel=(label)=>{
      labelBuca=label;
  }
	return(
		<Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        !landScapeError?
        <Box fill>
              <Box direction={width>=1100?"row":"column"}>
              {width>=1100?
                <SideBar pathLogo={props.match===undefined?"./images/Logo1.png":props.match.params.id!==undefined?"../images/Logo1.png":""} page="Geo ref." ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>      
            :  
            <AppBar position="static" style={{ background: '#006837'}}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Taskonnect
              </Typography>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                 if(!showSideBarMob){
                  setShowSideBarMob(true);
                 }else{
                   setShowSideBarMob(false);
                 }      
                }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
              }
               {showSideBarMob?
                  <SideBarMobile page="Attività" ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>
                 :
              <Box direction='column' flex overflow={{ horizontal: 'hidden' }} pad="small">
                  <Box direction="row" gap="small">
                    <Button style={{width:'150px',fontSize:'15px',background:"#006837",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px", borderRadius:"10px"}} onClick={() => 
                    window.location.href = window.location.origin + "/home"}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <LinkPrevious color="white"/>
                          <Text size="small" color="white">Indietro</Text>
                      </Box>
                    </Button> 
                    <Button style={{width:'250px',fontSize:'10px',background:"#0350281A",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px"}} onClick={()=>{setMostraLayerManual(true);setMostraLayer(true)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Text size="small" color="#006837">MOSTRA CONFIGURAZIONI</Text>
                      </Box>
                    </Button>
                  </Box>
                <Box flex direction='row' align="center" alignSelf="center">
                  <Box direction='column' pad="small" gap="medium">
                 
                      {(adl=='' || zona=='' || adl=='' || color=='') ?
                        <Box width="80%" style={{visibility: 'hidden'}}>
                          <MapWithADirectionsRenderer/>
                        </Box>
                        :
                        <Box >
                          <MapWithADirectionsRenderer/>
                        </Box>
                      }
                  </Box>
                  {mostraLayer?
                  <Layer onClickOutside={()=>setMostraLayer(false)} onEsc={()=>setMostraLayer(false)}>
                    <Box pad="medium" margin="2%" direction='column' gap="small" overflow="auto">
                    <Text> Configurazione per Tracciamento: </Text>
                    <Select
                      placeholder="Cantiere"
                      disabled={props.match==undefined?false:true}
                      value={cantiere}
                      options={[... new Set(cantieri.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Nome)))]}
                      onChange={event => setCant(event.target.value)}
                    />
                    <Select
                      placeholder="Zona"
                      value={zona}
                      disabled={props.match==undefined?false:true}
                      options={[... new Set(zone.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Zona)))]}
                      onChange={event => setZona(event.target.value)}
                    />
                    <Select
                      placeholder="Nome AdL"
                      value={adl}
                      disabled={props.match==undefined?false:true}
                      options={[... new Set(zone.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(cantiere => (cantiere.Localita)))]}
                      onChange={event => setAdl(event.target.value)}
                    />
                    <Select
                      placeholder="Tipologia Adl"
                      value={tipo}
                      disabled={props.match==undefined?false:true}
                      options={[... new Set(tipologie.map(tipologia => (tipologia)))]}
                      onChange={event => setTipo(event.target.value)}
                    />
                    <Select
                      placeholder="Seleziona il Colore"
                      value={colorSelected}
                      disabled={props.match==undefined?false:true}
                      options={[... new Set(colors.map(colore => (colore.key)))]}
                      onChange={event => 
                        colors.filter(function(item){
                          if (item.key == event.target.value) {
                            setColorSelected(item.key);
                          }
                          return item.key == event.target.value;
                        }).map(function({value}){
                          setColor(value);
                        
                        })
                      }
                    />
                    {props.match!=undefined ?
                    <Box direction="row" gap="small">
                      <TextFieldLabel handleLabel={handleLabel}></TextFieldLabel>
                    </Box>
                    :
                    null
                    }      
                    </Box>
                    <Button margin='2%' label="Chiudi" size="medium" onClick={() =>{ 
                      if(mostraLayerManual){
                        setMostraLayerManual(false);
                        setMostraLayer(false);
                      }else{
                        setMostraLayer(false)
                      }
                   }}  style={{fontSize:'20px',marginLeft: 'auto', marginRight: 'auto',background:'#ff0000',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                  </Layer>
                  :
                  null
                  }
                </Box>
                <Box direction="column" alignSelf="center" gap="small">
                {props.match!=undefined ?
                    <Box width="300px" direction="row" gap="small" alignSelf="center">
                      <TextFieldLabel handleLabel={handleLabel}></TextFieldLabel>
                    </Box>
                    :
                    null
                    }      
                  <Box direction="row" gap="small" alignSelf="center" >
                    <Button label={props.match==undefined?"Salva":"Aggiorna"} size="medium" icon={<Location color="white"/>} onClick={() => handleSave()}  style={{fontSize:'20px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>      
                    <Button label="Cancella poligono" size="medium" icon={<Close color="white"/>} onClick={() => deleteSelectedShape()}  style={{fontSize:'20px',background:'#ff0000',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                  </Box>
                </Box>
              </Box>
              }
            </Box>
        </Box>
        :
        <Box fill gap="small">
        <AppBar position="static" style={{ background: '#006837'}}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Taskonnect
              </Typography>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                 if(!showSideBarMob){
                  setShowSideBarMob(true);
                 }else{
                   setShowSideBarMob(false);
                 }      
                }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        <Box width="100%" height="100%" align="center" alignSelf="center" background="white">
          <Text margin="small" color="#04671E" size="large" weight="bold">GIRA LO SCHERMO PER UTILIZZARE TASKONNECT</Text>
        </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>   
	)}

export default Mappa;
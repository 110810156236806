import React, { useState, useEffect } from "react";
import { Box, Menu, Grid, Grommet, Accordion,AccordionPanel,Text, Table,Image, TableHeader, TableBody, Select, TableRow, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, ResponsiveContext, DropButton, InfiniteScroll, Notification as NotificationApp,FileInput,Layer as LayerGrommet } from 'grommet';
import { FormClose, Notification, Home, UserWorker, MapLocation, Analytics, Task, Configure, CircleInformation, Add } from "grommet-icons";
import Notifications from "./Notifications";
import AddDip from "./AddDip";
import AddLav from "./AddLav";
import AddMac from "./AddMac";
import AddErr from "./AddErr";
import AddProd from "./AddProd";
import AddArea from "./AddArea";
import AddAccessorio from "./AddAccessorio";
import AddRil from "./AddRil";
import AnagraficheDip from "./AnagraficheDip";
import AnagraficheLav from "./AnagraficheLav";
import AnagraficheMac from "./AnagraficheMac";
import AnagraficheErr from "./AnagraficheErr";
import AnagraficheProd from "./AnagraficheProd";
import AnagraficheAree from "./AnagraficheAree";
import AnagraficheAccessori from "./AnagraficheAccessori";
import AnagraficheRil from "./AnagraficheRil";
import SideBar from "./SideBar";
import firebase from '../firebase';
import  { Redirect } from 'react-router-dom';
import moment from "moment";
import { CircularProgress } from '@mui/material';
import { Divider,Button as ButtonMui } from "@mui/material";
import { AlertIcon } from "@chakra-ui/react";
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as Qr } from './qr.svg';

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#0350281A',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
      color:"#04671E",
    },
    select: {
      options:{
        extend:{
          fontWeight:'bold',
        },
      },
    },
  },
  layer: {
    border: {
      radius: 'large',
      intelligentRounding: true,
    },
  },
};

const App = () => {
  const [dipendenti, setDipendenti] = useState([]);
  const [lavori, setLavori] = useState([]);
  const [macchine, setMacchinari] = useState([]);
  const [prodotti, setProdotti] = useState([]);
  const [areeLav, setAreeLav] = useState([]);
  const [accessori, setAccessori] = useState([]);
  const [rilievi, setRilievi] = useState([]);
  const [errori, setErrori] = useState([]);
  const dip = firebase.firestore().collection('Dipendenti');
  const lav = firebase.firestore().collection('Lavori');
  const mac = firebase.firestore().collection('Macchinari');
  const prod = firebase.firestore().collection('Prodotti');
  const aree = firebase.firestore().collection('Aree');
  const acc = firebase.firestore().collection('Accessori');
  const [dropdown, setDropdown] = useState('');
  const ril = firebase.firestore().collection('RilievoOre');
  const err = firebase.firestore().collection('Errori');
  const man = firebase.firestore().collection('Manutenzione');
  const [showLayer,setShowLayer]=React.useState(false);
  const [filePicked,setFilePicked]=React.useState();
  const [progress,setProgress]=React.useState(0);
  const [showLayerCant,setShowLayerCant]=React.useState(false);
  const [showLayerDip,setShowLayerDip]=React.useState(false);
  const [showLayerLavori,setShowLayerLavori]=React.useState(false);
  const [showLayerMac,setShowLayerMac]=React.useState(false);
  const [showLayerProd,setShowLayerProd]=React.useState(false);
  const [showLayerAcc,setShowLayerAcc]=React.useState(false);
  const [showLayerRil,setShowLayerRil]=React.useState(false);
  const [showLayerErr,setShowLayerErr]=React.useState(false);
  const [modifyAree,setModifyAree]=React.useState(false);
  const [areaSel,setAreaSel]=React.useState();
  const [modifyPersonale,setModifyPersonale]=React.useState(false);
  const [dipSel,setDipSel]=React.useState(false);
  const [modifyLavori,setModifyLavori]=React.useState(false);
  const [lavSel,setLavSel]=React.useState(false);
  const [modifyMacchinari,setModifyMacchinari]=React.useState(false);
  const [macSel,setMacSel]=React.useState(false);
  const [modifyRilievo,setModifyRilievo]=React.useState(false);
  const [rilSel,setRilSel]=React.useState(false);
  const [modifyErrore,setModifyErrore]=React.useState(false);
  const [errSel,setErrSel]=React.useState(false);

  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const role = firebase.firestore().collection('Ruoli');
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/

  /*CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/
  const notify = firebase.firestore().collection('Notifiche');
  const [notifiche, setNotifiche] = useState([]);
  /*END CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/

  var QRCode_Gen = require('qrcode.react');

  React.useEffect(()=>{
    if(!showLayer){
      setProgress(0);
    }
  },[showLayer])


/**
	* Funzione che cancella un area
*/
  function onDeleteArea(){
    var result = window.confirm("Sei sicuro di voler eliminare il cantiere?");
    if(result){
      const db = firebase.firestore();
      db.collection('Aree').doc(areaSel.id).delete();
      setModifyAree(false);
    }
  }

  
/**
	* Funzione che cancella un dipendente
*/
  const onDeleteDip = () => {
    var result = window.confirm("Sei sicuro di voler eliminare il dipendente?");
    if(result){
      const db = firebase.firestore();
      db.collection('Dipendenti').doc(dipSel.id).delete();
      var ruoli_query = db.collection('Ruoli').where('Utente', '==', dipSel.email);
      ruoli_query.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.ref.delete();
        });
      });
      setModifyPersonale(false);
    }
    }

    /**
 * Funzione che cancella un lavoro
 */
  const onDeleteLav = () => {
    var result = window.confirm("Sei sicuro di voler eliminare il lavoro?");
    if(result){
      const db = firebase.firestore();
      db.collection('Lavori').doc(lavSel.id).delete();
      setModifyLavori(false);
    }
  }

  /**
 * Funzione che cancella un rilievo
 */
   const onDeleteRil = () => {
    var result = window.confirm("Sei sicuro di voler eliminare il rilievo?");
    if(result){
      const db = firebase.firestore();
      db.collection('RilievoOre').doc(rilSel.id).delete();
      setModifyRilievo(false);
    }
  }

  /**
 * Funzione che cancella un errore 
 */
   const onDeleteErrore = () => {
    var result = window.confirm("Sei sicuro di voler eliminare l'errore?");
	  if(result){
	    const db = firebase.firestore();
      db.collection('Errori').doc(errSel.id).delete();
      setModifyErrore(false);
	  }
  }

  /**
 * Funzione che cancella un macchinario 
 */
   const onDeleteMac = () => {
    var result = window.confirm("Sei sicuro di voler eliminare il macchinario?");
    if(result) {
      const db = firebase.firestore();
      db.collection('Manutenzione').where('Telaio', '==', macSel.Telaio).onSnapshot((querySnapshot) => {
        const items = []
            querySnapshot.forEach((doc) => {
              items.push( { ... doc.data(), id: doc.id } );
            });
            if (items.length !== 0) {
              db.collection('Manutenzione').doc(items[0].id).delete();
            }
        });
      db.collection('Macchinari').doc(macSel.id).delete();
      setModifyMacchinari(false);
    }	
    }

  /**
   * Funzione che rileva tutti i dipendenti presenti a sistema
   */
  function getDipendenti() {
        dip.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setDipendenti(items);        
        });
  }

   /**
   * Funzione che rileva tutti i lavori presenti a sistema
   */
  function getLavori() {
        lav.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setLavori(items);       
        });
  }

  /**
	 * Funzione che genera e scarica il qrCode di un macchinario
	 */
   function downloadQR(nome) {
    const canvas = document.getElementById(nome);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr-"+nome+".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

   /**
   * Funzione che rileva tutti i macchinari presenti a sistema
   */
  function getMacchinari() {
        mac.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setMacchinari(items);
          
        });
  }

   /**
   * Funzione che rileva tutti i prodotti presenti a sistema
   */
  function getProdotti() {
    if(ruoli[0].Ruolo=="Admin_Cantiere") {
        prod.where("Cantiere","array-contains",sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setProdotti(items);
          
        });
    } else if (ruoli[0].Ruolo=="Admin_Zona") {
      prod.where("Zona","array-contains",sessionStorage.getItem('Cantiere')).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setProdotti(items);
        
      });
  }else if (ruoli[0].Ruolo=="Admin") {
    prod.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      setProdotti(items);    
    });
  }
}

 /**
   * Funzione che rileva cantieri/zone e adl presenti a sistema
   */
  function getAree() {
        aree.orderBy("Zona").onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
          /*var dataOdierna=moment(new Date()).toDate();
            var dataScadZona=moment(doc.data().)*/
            items.push( { ... doc.data(), id: doc.id } );
          });
          setAreeLav(items);         
        });
    }

    /**
   * Funzione che rileva tutti gli accessori presenti a sistema
   */
  function getAccessori() {
        acc.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setAccessori(items);       
        });
  }


  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  /**
   * Funzione che rileva il ruolo dell'utente loggato
   */
  function getRuoli() {
      role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setRuoli(items);     
      });

  }

  /**
   * Funzione che rileva le notifiche dell'utente loggato
   */
  function getNotifiche() {
      notify.where("email", "==", userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setNotifiche(items);
        ;
      });
  }


  /**
   * Funzione che rileva in base al ruolo dell'utente loggato e al cantiere selezionato
   * i rilievi delle macchine non 4.0
   */
  function getRilievo() {
    if(ruoli[0].Ruolo=="Admin") {
        ril.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setRilievi(items);
          
        });
    } else if(ruoli[0].Ruolo=="Admin_Cantiere") {   
      mac.where("Cantiere","array-contains", sessionStorage.getItem('Cantiere')).where("NomeMacchina","!=","NESSUNA").onSnapshot((querySnapshot)=>{
        const elencoMac=[];
        querySnapshot.forEach((doc)=>{
          elencoMac.push(doc.data().Telaio);
        })   
        ril.onSnapshot((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            ;
            if(elencoMac.indexOf(doc.data().Telaio)!==-1){ //Sopperisce a limti firebase (array max di 10 elementi)
              items.push( { ... doc.data(), id: doc.id } ); 
            }
          });
          setRilievi(items);
        });
    })
  }else if(ruoli[0].Ruolo=="Admin_Zona") {
    mac.where("Cantiere","array-contains", sessionStorage.getItem('Cantiere')).where("NomeMacchina","!=","NESSUNA").onSnapshot((querySnapshot)=>{
      const elencoMac=[];
      querySnapshot.forEach((doc)=>{
        elencoMac.push(doc.data().NomeMacchina);
      })   
      ril.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          if(elencoMac.indexOf(doc.data().Telaio)!==-1){ //Sopperisce a limti firebase (array max di 10 elementi)
            items.push( { ... doc.data(), id: doc.id } );
          }
        });
        setRilievi(items);
      });
    })
  }
}

  /*
    Funzione che rileva tutti gli errori dei macchinari presenti a sistema
  */
  function getErrori() {
    err.orderBy("Modello").onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push( { ... doc.data(), id: doc.id } );
      });
      console.log(items);
      setErrori(items);
    });
  }

  useEffect(()=>{
    if(ruoli[0] !== undefined){
      getProdotti();
      getRilievo();
    }
  },[ruoli])

  useEffect(() => {
    getRuoli();
    getDipendenti();
    getLavori();
    getMacchinari();
    if(ruoli[0] !== undefined){
      getProdotti();
      getRilievo();
    }
    getErrori();
    getAree();
    getAccessori();
    getNotifiche();
  }, []);

  if (sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]"));

  /**
   * Funzione che gestisce la chiusura del layer per aggiunta anagrafiche in base alla tipologia di anagrafica selezionata
   */
  function onEscLayer(){
    if(dropdown=="CANTIERI"){
      setShowLayerCant(false);
    }else if(dropdown=="PERSONALE"){
      setShowLayerDip(false);
    }else if(dropdown=="LAVORI"){
      setShowLayerLavori(false);
    }else if(dropdown=="MACCHINARI"){
      setShowLayerMac(false);
    }else if(dropdown=="PRODOTTI"){
      setShowLayerProd(false);
    }else if(dropdown=="ACCESSORI"){
      setShowLayerAcc(false);
    }else if(dropdown=="RILIEVO"){
      setShowLayerRil(false);
    }else if(dropdown=="ERRORI"){
      setShowLayerErr(false);
    }
  }

  /**
   * Funzione crea un Layer contente il componente di aggiunta anagrafica corretto in base alla tipologia di anagrafica selezionata
   */
  function LayerAggiungiAnagrafica(props){
    return(
      <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
        <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
          <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
        {
          props.anagrafica=="Cantieri"?
          <AddArea onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Personale"?
          <AddDip onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Lavori"?
          <AddLav onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Macchinari"?
            <AddMac onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Prodotti"?
          <AddProd onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Accessori"?
          <AddAccessorio onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Rilievi"?
          <AddRil onEscLayer={onEscLayer}/>
          :
          props.anagrafica=="Errori"?
          <AddErr onEscLayer={onEscLayer}/>
          :
          null
        }
        </Box>
      </LayerGrommet>
    )
  }

  /**
   * Layer per caricare file .json contente anagrafiche
   */
  function LayerCaricatore(){
    return(
   
          <LayerGrommet
          onEsc={() => {setShowLayer(false);setFilePicked()}}
          onClickOutside={() => {setShowLayer(false);setFilePicked()}}
        >
             <Box  align="center" pad="medium" gap="medium" direction="column">
              <FileInput
                multiple={{
                  max: 1,
                }}
                name="file"
                onChange={event => {
                  const fileList = event.target.files;
                  caricaAnagrafiche(dropdown,fileList[0]);
                }}
              />
              {filePicked===null?
              <Text style={{color:"red"}}>File non compatibile</Text>
              :
              null}
              <CircularProgress variant="determinate" value={progress} />
              <Button label="Chiudi" onClick={() =>{ setShowLayer(false);setFilePicked()}} /> 
           </Box>
         
        </LayerGrommet>
   
    )
  }

  /**
   * Funzione che carica le anagrafiche lette dal file .json caricato dall'utente
   */
  function caricaAnagrafiche(cat,file){
    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    if(cat==="ERRORI" && file.name==="ERRORI.json"){  
      if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
      reader.onload = function (evt) {
        const json=JSON.parse(evt.target.result);
        var error=false;
        json.forEach(function(obj,index) {
          setProgress((index/json.length)*100)
          if(typeof obj.Modello==="string" && typeof obj.codiceDTC==="string" && typeof obj.descrizioneDTC==="string" && typeof obj.unitaComando==="string") {
            try{
              err.add({
                Modello: obj.Modello,
                codiceDTC: obj.codiceDTC,
                descrizioneDTC: obj.descrizioneDTC,
                unitaComando: obj.unitaComando,
            }).then(function(docRef) {

            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
            }catch(error){
              console.error("Error adding document: ",error);
              alert("Errore nel caricamento");
              setShowLayer(false);
            }             
          }else{
            alert("Alcuni dati non sono nel formato corretto, controllare il file .CSV")
          }
        });
        if(error){
          alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
          setShowLayer(false);
        }else{
          setShowLayer(false);
          alert("Caricamento completato con successo");  
        }
      }
      reader.onerror = function (evt) {
          alert("Errore nella lettura del file");
      }
    }
    }else if(cat==="CANTIERI"  && file.name==="CANTIERI.json"){
      if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
        reader.onload = function (evt) {
          const json=JSON.parse(evt.target.result);
          var error=false;
          json.forEach(function(obj,index) {
            setProgress((index/json.length)*100)
            if(typeof obj.Nome==="string" && obj.Nome!=="" && typeof obj.InizioCantiere==="string" && typeof obj.FineCantiere==="string" && typeof obj.Zona==="string" && obj.Zona!=="" && typeof obj.InizioZona==="string" && typeof obj.FineZona==="string" && typeof obj.Localita==="string" && obj.Localita!=="" && typeof obj.InizioAdl==="string" && typeof obj.FineAdl==="string" && typeof obj.Home==="object") {
            try{
              aree.add({
                Nome: obj.Nome,
                InizioCantiere: obj.InizioCantiere, 
                FineCantiere: obj.FineCantiere, 
                Zona: obj.Zona, 
                InizioZona:obj.InizioZona, 
                FineZona: obj.FineZona, 
                Localita: obj.Localita,
                InizioAdl: obj.InizioAdl,
                FineAdl: obj.FineAdl, 
                Home: obj.Home,
               // CodiceGestionale: obj.CodiceGestionale??
                }).then(function(docRef) {

              })
              .catch(function(error) {
                  console.error("Error adding document: ", error);
              });
            }catch(error){
              alert("ERORRE nel caricamento",error);
              setShowLayer(false);
            }   
          }else{
           error=true;
          }        
          });
          if(error){
            alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
            setShowLayer(false);
          }else{
            setShowLayer(false);
            alert("Caricamento completato con successo");  
          }
        }
        reader.onerror = function (evt) {
            alert("Errore nella lettura del file");
        }
      }
    }else if(cat==="PERSONALE" && file.name==="PERSONALE.json"){
      if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
      reader.onload = function (evt) {
        const json=JSON.parse(evt.target.result);
        var error=false;
        json.forEach(function(obj,index) {
          setProgress((index/json.length)*100)
          if(typeof obj.Nome==="string" && typeof obj.Cognome==="string" && (typeof obj.Matricola==="string" || typeof obj.Matricola==="number") && obj.Matricola!=="" &&  typeof obj.Mansione==="string" &&  typeof obj.DataNascita==="string" &&  typeof obj.CodiceFiscale==="string" &&  typeof obj.Email==="string" && ( typeof obj.Cellulare==="string" || typeof obj.Cellulare==="number" ) &&  (typeof obj.CostoInt==="string" || typeof obj.CostoInt==="number") && (typeof obj.CostoEst==="string" || typeof obj.CostoEst==="number") && typeof obj.Ruolo==="string" && typeof obj.Cantiere==="object" && typeof obj.Zona==="object"){
            try{
              dip.add({ Nome: obj.Nome, Cognome: obj.Cognome, Matricola: obj.Matricola, Mansione: obj.Mansione, DataNascita: obj.DataNascita, CodiceFiscale: obj.CodiceFiscale, email: obj.Email, Cellulare: obj.Cellulare, CostoInt: obj.CostoInt===""?0: obj.CostoInt, CostoEst: obj.CostoEst===""?0:obj.CostoEst, Ruolo: obj.Ruolo, Cantiere: obj.Cantiere, Zona: obj.Zona })
            .then(function(docRef) {

             })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
            role.add({ Ruolo: obj.Ruolo, Utente: obj.Email })
            .then(function(docRef) {

             })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
            }catch(error){
             alert("ERORRE nel caricamento",error);
             setShowLayer(false);
            }    
          }else{
            error=true;
          }
         
        });
        if(error){
          alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
          setShowLayer(false);
        }else{
          setShowLayer(false);
          alert("Caricamento completato con successo");  
        }
      }
      reader.onerror = function (evt) {
          alert("Errore nella lettura del file");
      }
    }
    }else if(cat==="LAVORI" && file.name==="LAVORI.json"){
      if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
      reader.onload = function (evt) {
        const json=JSON.parse(evt.target.result);
        var error=false;
        json.forEach(function(obj,index) {
          setProgress((index/json.length)*100)
          if(typeof obj.Nome==="string" && obj.Nome!==""  && (typeof obj.Durata==="string" || typeof obj.Durata==="number") && typeof obj.Cantiere==="object" && typeof obj.Zona==="object" && typeof obj.Adl==="object" && typeof obj.CodiceGestionale==="string"){
            try{
              lav.add({ 
                Nome: obj.Nome,
                Durata: obj.Durata, 
                Cantiere: obj.Cantiere, 
                Zona: obj.Zona, 
                Adl: obj.Adl, 
                CodiceGestionale: obj.CodiceGestionale })
                .then(function(docRef) {

              })
              .catch(function(error) {
                  console.error("Error adding document: ", error);
              });
            }catch(error){
              alert("ERORRE nel caricamento",error);
              setShowLayer(false);
            }
          }else{
            error=true;
          } 
        });
        if(error){
          alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
          setShowLayer(false);
        }else{
          setShowLayer(false);
          alert("Caricamento completato con successo");  
        }
      }
      reader.onerror = function (evt) {
          alert("Errore nella lettura del file");
      }
    }
  }else if(cat==="MACCHINARI"  && file.name==="MACCHINARI.json"){
    if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
    reader.onload = function (evt) {
      const json=JSON.parse(evt.target.result);
      var error=false;
      json.forEach(function(obj,index) {
        setProgress((index/json.length)*100)
        if(typeof obj.Adl==="object" && typeof obj.Cantiere==="object" && typeof obj.Zona==="object" && typeof obj.Lavori==="object" && (typeof obj.Telaio==="string" || typeof obj.Telaio==="number") && obj.Telaio!==""){
          try{
            mac.add({
              NomeMacchina: obj.NomeMacchina, 
              Tagliando: obj.Tagliando, 
              Telaio: obj.Telaio, 
              Marca: obj.Marca, 
              UltimoUtilizzo: obj.UltimoUtilizzo, 
              DimensioneTaglio: obj.DimensioneTaglio, 
              TempoMacchinaIniziale: obj.TempoMacchinaIniziale, 
              TempoMacchinaAttuale: obj.TempoMacchinaAttuale,   
              Tipologia: obj.Tipologia, 
              Alimentazione: obj.Alimentazione, 
              Societa: obj.Societa, 
              Anno: obj.Anno, 
              Note: obj.Note, 
              CostoOrario: obj.CostoOrario===""?0:obj.CostoOrario, 
              ValoreMacchina: obj.ValoreMacchina, 
              TariffaOraria:  obj.TariffaOraria===""?0: obj.TariffaOraria, 
              FiltroOlioIdraulico: obj.FiltroOlioIdraulico, 
              FiltroOlio: obj.FiltroOlio, 
              Lame: obj.Lame,
              OlioMotore: obj.OlioMotore,
              FiltroOlioMotore: obj.FiltroOlioMotore,
              FiltroGasolio: obj.FiltroGasolio,
              FiltroBenzina: obj.FiltroBenzina,
              FiltroAria: obj.FiltroAria,
              OlioIdraulico: obj.OlioIdraulico,
              Ingrassatore: obj.Ingrassatore,
              Lappatura: obj.Lappatura,
              Candele: obj.Candele,
              Accessori: obj.Accessori,
              Cantiere: obj.Cantiere, 
              Zona: obj.Zona,
              Adl: obj.Adl,
              Lavori: obj.Lavori,
              CanId: obj.CanId,
              CodiceGestionale: obj.CodiceGestionale,
              Accesa: obj.Accesa,
            });
    /*CHANGE PER MANUTENZIONE MACCHINE*/
                man.add({
                NomeMacchina: obj.NomeMacchina,
                Telaio: obj.Telaio,
                OreCalcolate: obj.OreCalcolate,
                CambioFiltroOlioIdraulico: obj. CambioFiltroOlioIdraulico,
                CambioFiltroOlio:obj. CambioFiltroOlio,
                AffilareLame: obj.AffilareLame,
                CambioOlioMotore: obj.CambioOlioMotore,
                CambioFiltroOlioMotore: obj.CambioFiltroOlioMotore,
                CambioFiltroGasolio: obj.CambioFiltroGasolio,
                CambioFiltroBenzina: obj.CambioFiltroBenzina,
                CambioFiltroAria: obj.CambioFiltroAria,
                CambioOlioIdraulico: obj.CambioOlioIdraulico,
                EffettuareIngrassatura: obj.EffettuareIngrassatura,
                EffettuareLappatura: obj.EffettuareLappatura,
                EffettuareCandele: obj.EffettuareCandele,
                Cantiere: obj.Cantiere, 
                Zona: obj.Zona,
                CanId: obj.CanId,
                CodiceGestionale: obj.CodiceGestionale})
            .then(function(docRef) {

            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
        
          }catch(error){
              alert("Errore nel caricamento delle anagrafiche");
              setShowLayer(false);
          } 
        }else{
          error=true;
        }
      });  
      if(error){
        alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
        setShowLayer(false);
      }else{
        setShowLayer(false);
        alert("Caricamento completato con successo");  
      }
    }
    reader.onerror = function (evt) {
        alert("Errore nella lettura del file");
    }
  }
  }else if(cat==="PRODOTTI"  && file.name==="PRODOTTI.json"){
    if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
    reader.onload = function (evt) {
      const json=JSON.parse(evt.target.result);
      var error=false;
      json.forEach(function(obj,index) {
        setProgress((index/json.length)*100) 
        if(typeof obj.Nome==="string" && typeof obj.Produttore==="string" && (typeof obj.Qta==="string" || typeof obj.Qta==="number") && (typeof obj.Um==="string" || typeof obj.Um==="number") && (typeof obj.Valore==="string" || typeof obj.Valore==="number") &&  typeof obj.Cantiere==="object" && typeof obj.Zona==="object" && (typeof obj.Sku==="string" || typeof obj.Sku==="number") && obj.Sku!==""){
          try{
            prod.add({ 
              Nome: obj.Nome, 
              Produttore: obj.Produttore, 
              Qta: obj.Qta, 
              Um: obj.Um,
              Valore: obj.Valore, 
              Cantiere: obj.Cantiere, 
              Zona: obj.Zona, 
              Sku:obj.Sku })
            .then(function(docRef) {

            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
          }catch(error){
            console.error("Error adding document: ",error);
            alert("Errore nel caricamento");
            setShowLayer(false);
          }
        }else{
          error=true;
        }      
      });
      if(error){
        alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
        setShowLayer(false);
      }else{
        setShowLayer(false);
        alert("Caricamento completato con successo");  
      }
    }
    reader.onerror = function (evt) {
        alert("Errore nella lettura del file");
    }
  }
  }else if(cat==="ACCESSORI"  && file.name==="ACCESSORI.json"){
    if(window.confirm("File compatibile! Procedere con il caricamento?")==true){
    reader.onload = function (evt) {
      const json=JSON.parse(evt.target.result);
      var error=false;
      json.forEach(function(obj,index) {
        setProgress((index/json.length)*100)
        if(typeof obj.Nome==="string" && obj.Nome!=="" && typeof obj.Cantiere==="object" && typeof obj.Zona==="object" && (typeof obj.Matricola==="string" || typeof obj.Matricola==="number")){
          try{
            acc.add({ 
              Nome: obj.Nome, 
              Cantiere: obj.Cantiere,
              Zona: obj.Zona, 
              Matricola: obj.Matricola })
            .then(function(docRef) {

            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
          }catch(error){
             alert("Errore nel caricamento");
            setShowLayer(false);
          } 
        }else{
          error=true;
        }
      });
      if(error){
        alert("Caricamento parzialmente eseguito: alcuni dati non sono nel formato corretto, controllare il file .CSV")
        setShowLayer(false);
      }else{
        setShowLayer(false);
        alert("Caricamento completato con successo");  
      }   
    }
    reader.onerror = function (evt) {
        alert("Errore nella lettura del file");
    }
  }
  }else{
    alert("File non corretto, il nome del file deve essere: NOMECATEGORIA.json")
    setFilePicked(null);
  }
}

  /**
   * Funzione che gestisce l'apertura del layer per aggiunta anagrafiche in base alla tipologia di anagrafica selezionata
   */
  function mostraLayer(){
    if(dropdown=="CANTIERI"){
      setShowLayerCant(true);
    }else if(dropdown=="PERSONALE"){
      setShowLayerDip(true);
    }else if(dropdown=="LAVORI"){
      setShowLayerLavori(true);
    }else if(dropdown=="MACCHINARI"){
      setShowLayerMac(true);
    }else if(dropdown=="PRODOTTI"){
      setShowLayerProd(true);
    }else if(dropdown=="ACCESSORI"){
      setShowLayerAcc(true);
    }else if(dropdown=="RILIEVO"){
      setShowLayerRil(true);
    }else if(dropdown=="ERRORI"){
      setShowLayerErr(true);
    }
  }

  /**
   * Funzione che ritorna il nome della tipologia di anagrafica selezionata al singolare
   */
  function getStringAnagrafica(){
    if(dropdown=="CANTIERI"){
      return "CANTIERE";
    }else if(dropdown=="PERSONALE"){
      return "PERSONALE";
    }else if(dropdown=="LAVORI"){
      return "LAVORO";
    }else if(dropdown=="MACCHINARI"){
      return "MACCHINARIO";
    }else if(dropdown=="PRODOTTI"){
      return "PRODOTTO";
    }else if(dropdown=="ACCESSORI"){
      return "ACCESSORIO";
    }else if(dropdown=="RILIEVO"){
      return "RILIEVO"
    }else if(dropdown=="ERRORI"){
      return "ERRORE"
    }
  }

  const columnsCantieri = [
    { field: 'Nome', headerName: 'Cantiere', width: 170 ,
      renderCell: (cellValues) => {
        var CantScaduto;
      /**
      * Controllo cantiere
      */
        var dataOdierna=moment(new Date()).toDate();
        var dataFineToConvert=cellValues.row.FineCantiere;
        //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
        var day=dataFineToConvert.substring(0,2);
        var month=dataFineToConvert.substring(3,5);
        dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
          
        dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
        var dataFine=moment(dataFineToConvert).toDate();
        if(dataOdierna.getFullYear() < dataFine.getFullYear()){
          CantScaduto=false;
        }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
          CantScaduto=false;
        }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
          CantScaduto=false;
        }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){
          CantScaduto=true;
        }else{
          CantScaduto=true;
        }
        return (
          <Text
            color={CantScaduto?"red":"black"}
            size="small"
          >
            {cellValues.row.Nome}
          </Text>
        );},
    },
    { field: 'InizioCantiere', headerName: 'Inizio Cantiere', width: 120 },
    { field: 'FineCantiere', headerName: 'Fine Cantiere', width: 120  },
    {
      field: 'Zona',
      headerName: 'Zona',
      width: 170,
      renderCell: (cellValues) => {
        var ZonaScaduta;
        var dataOdierna=moment(new Date()).toDate();
        var dataFineToConvert=cellValues.row.FineZona;
        //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
        var day=dataFineToConvert.substring(0,2);
        var month=dataFineToConvert.substring(3,5);
        dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
          
        dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
        var dataFine=moment(dataFineToConvert).toDate();
        dataFineToConvert=cellValues.row.FineZona;
        day=dataFineToConvert.substring(0,2);
        month=dataFineToConvert.substring(3,5);
        dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
        dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
        dataFine=moment(dataFineToConvert).toDate();
        if(dataOdierna.getFullYear() < dataFine.getFullYear()){
          ZonaScaduta=false;
        }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
          ZonaScaduta=false;
        }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
          ZonaScaduta=false;
        }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){
          ZonaScaduta=true;
         }else{
          ZonaScaduta=true;
        }
        return (
          <Text
            color={ZonaScaduta?"red":"black"}
            size="small"
          >
            {cellValues.row.Zona}
          </Text>
        );},
    },
    {
      field: 'InizioZona',
      headerName: 'Inizio Zona',
      width: 120,
    },
    {
      field: 'FineZona',
      headerName: 'Fine Zona',
      width: 120,
    },
   {
    field: 'Localita',
    headerName: 'Area di lavoro',
    width: 170,
    renderCell: (cellValues) => {
      var AdlScaduta;
      var dataOdierna=moment(new Date()).toDate();
      var dataFineToConvert=cellValues.row.FineAdl;
      //moment() non accetta date in formato dd:mm:yyyy, devo convertirle manualmente in mm:dd:yyyy
      var day=dataFineToConvert.substring(0,2);
      var month=dataFineToConvert.substring(3,5);
      dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
        
      dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
      var dataFine=moment(dataFineToConvert).toDate();
      dataFineToConvert=cellValues.row.FineAdl;
      dataFineToConvert=cellValues.row.FineAdl;
      day=dataFineToConvert.substring(0,2);
      month=dataFineToConvert.substring(3,5);
      dataFineToConvert=replaceRange(dataFineToConvert, 0, 2, month);
      dataFineToConvert=replaceRange(dataFineToConvert, 3, 5, day);
      dataFine=moment(dataFineToConvert).toDate();
      if(dataOdierna.getFullYear() < dataFine.getFullYear()){
        AdlScaduta=false;
      }else if(dataOdierna.getFullYear() == dataFine.getFullYear() && dataOdierna.getMonth() < dataFine.getMonth()){
        AdlScaduta=false;
      }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() < dataFine.getDate()){
        AdlScaduta=false;
      }else if(dataOdierna.getMonth() == dataFine.getMonth() && dataOdierna.getDate() == dataFine.getDate()){
        AdlScaduta=true;
      }else{
        AdlScaduta=true;
      }
      return (
        <Text
          color={AdlScaduta?"red":"black"}
          size="small"
        >
          {cellValues.row.Localita}
        </Text>
      );},
  },
   {
     field: 'InizioAdl',
     headerName: 'Inizio Zona',
     width: 120,
   },
   {
     field: 'FineAdl',
     headerName: 'Fine Zona',
     width: 120,
   },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            console.log(cellValues.row)
            setAreaSel(cellValues.row)
            setModifyAree(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   }
  ];

  const columnsDip = [
    { field: 'Nome', headerName: 'Nome', width: 170 },
    { field: 'Cognome', headerName: 'Cognome', width: 170 },
    { field: 'Matricola', headerName: 'Matricola', width: 120  },
    {
      field: 'email',
      headerName: 'Email',
      width: 170,
    },
    {
      field: 'DataNascita',
      headerName: 'Data Nascita',
      width: 120,
    },
    {
      field: 'Cellulare',
      headerName: 'Cellulare',
      width: 120,
    },
   {
    field: 'CostoInt',
    headerName: 'Costo Interno',
    width: 100,
  },
   {
     field: 'CostEst',
     headerName: 'Costo Esterno',
     width: 100,
   },
   {
     field: 'Ruolo',
     headerName: 'Ruolo',
     width: 150,
   },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            setDipSel(cellValues.row)
            setModifyPersonale(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   }
  ];

  const columnsLav = [
    { field: 'Nome', headerName: 'Nome', width: 300 },
    { field: 'Durata', headerName: 'Durata', width: 100 },
    { field: 'Cantiere', headerName: 'Cantiere', width: 300  },
    {
      field: 'Zona',
      headerName: 'Zona',
      width: 300,
    },
    {
      field: 'Adl',
      headerName: 'Area di lavoro',
      width: 300,
    },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            setLavSel(cellValues.row)
            setModifyLavori(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   }
  ];

  const columnRil = [
    { field: 'MatricolaOperatore', headerName: 'Matricola', width: 170 },
    { field: 'CognomeOperatore', headerName: 'Cognome', width: 170 },
    { field: 'NomeOperatore', headerName: 'Nome', width: 120  },
    {
      field: 'DataRilievo',
      headerName: 'Data Rilievo',
      width: 170,
    },
    {
      field: 'Telaio',
      headerName: 'Telaio',
      width: 120,
    },
    {
      field: 'Modello',
      headerName: 'Modello',
      width: 120,
    },
   {
    field: 'OreCalcolate',
    headerName: 'Ore Calcolate',
    width: 100,
  },
   {
     field: 'OreRilevate',
     headerName: 'Ore Rilevate',
     width: 100,
   },
   {
     field: 'Delta',
     headerName: 'Delta',
     width: 150,
   },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            setRilSel(cellValues.row)
            setModifyRilievo(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   }
  ];

  const columnErr = [
    { field: 'Modello', headerName: 'Modello', width: 170 },
    { field: 'codiceDTC', headerName: 'Codice DTC', width: 200 },
    { field: 'descrizioneDTC', headerName: 'Descrizione DTC', width: 700  },
    {
      field: 'unitaComando',
      headerName: 'Unità Comando ',
      width: 200,
    },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            setErrSel(cellValues.row)
            setModifyErrore(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   }
  ];

  const columnsMac = [
    { field: 'NomeMacchina', headerName: 'Nome Macchina', width: 170 },
    { field: 'Tagliando', headerName: 'Tagliando', width: 120 },
    { field: 'Telaio', headerName: 'Telaio', width: 120 },
    { field: 'CanId', headerName: 'CanId', width: 120  },
    {
      field: 'Marca',
      headerName: 'Marca',
      width: 170,
    },
    {
      field: 'Accessori',
      headerName: 'Accessori',
      width: 120,
    },
    {
      field: 'UltimoUtilizzo',
      headerName: 'Ultimo utilizzo',
      width: 120,
    },
   {
    field: 'DimensioneTaglio',
    headerName: 'Dimensione Di Taglio (cm)',
    width: 170,
  },
   {
     field: 'TempoMacchinaIniziale',
     headerName: 'Ore Macchina Iniziale',
     width: 120,
   },
   {
    field: 'Societa',
    headerName: 'Societa',
    width: 170,
  },
   {
     field: 'Tipologia',
     headerName: 'Tipologia',
     width: 120,
   },
   {
     field: 'Alimentazione',
     headerName: 'Alimentazione',
     width: 120,
   },
   {
    field: 'Anno',
    headerName: 'Anno (acquisto)',
    width: 170,
  },
   {
     field: 'Note',
     headerName: 'Note',
     width: 120,
   },
   {
     field: 'CostoOrario',
     headerName: 'Costo Orario €/h (interno)',
     width: 120,
   },
   {
    field: 'ValoreMacchina',
    headerName: 'Valore Macchina €',
    width: 170,
  },
   {
     field: 'TariffaOraria',
     headerName: 'Tariffa Oraria Noleggio (€/h)',
     width: 120,
   },
   {
     field: 'FiltroOlioIdraulico',
     headerName: 'Filtro Olio Idraulico',
     width: 120,
   },
   {
    field: 'Filtro',
    headerName: 'Filtro',
    width: 120,
  },
  {
    field: 'Lame',
    headerName: 'Lame',
    width: 120,
  },
  {
    field: 'QRCode',
    headerName: 'Qr Code',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            downloadQR(cellValues.row.NomeMacchina);
          }}
        >
          <QRCode_Gen
				  	style={{display: 'none'}}
				    id={cellValues.row.NomeMacchina}
				    value={cellValues.row.NomeMacchina}
				    size={290}
				    level={"H"}
				    includeMargin={true}
				  />
          Download
        </ButtonMui>
      );},
   },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            setMacSel(cellValues.row)
            setModifyMacchinari(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   }
  ];

  return (
    <Grommet theme={theme} themeMode="dark" >
      <ResponsiveContext.Consumer>
        {size => (
        <Box height="97vh" width="100%" overflow="hidden" pad="small">
        {
          userLogged.email==="prova.admin@prova.com"?
        
          <Box direction="row" gap="medium" width="100%" > 
                <Box width="100%">
                    <Box fill>
                      <Box direction="column" align='start' >
                        <Box alignSelf='start' direction="row" align="center" margin="small" style={{textTransform:'uppercase'}} >
                            <Text color="#04671E" margin="small" style={{fontWeight:'bold'}}>Seleziona l'anagrafica: </Text>
                            <Select
                           style={{fontWeight:'normal',textTransform:'uppercase'}}
                              margin="small"
                              options={ruoli[0] !== undefined ? ruoli[0].Ruolo == 'Admin' ?['CANTIERI', 'PERSONALE', 'LAVORI', 'MACCHINARI', /*'PRODOTTI', 'ACCESSORI',*/ 'RILIEVO', 'ERRORI']:(ruoli[0].Ruolo=='Admin_Cantiere' || ruoli[0].Ruolo=="Admin_Zona")?[/*'PRODOTTI'*/, 'RILIEVO']:[]:[]}
                              value={dropdown}
                              onChange={({ option }) => setDropdown(option)}
                            />
                            { dropdown!=""?
                            <>
                            <Divider orientation="vertical"/>
                            <Box pad="small" direction="row" gap="small" >
                               {dropdown!="RILIEVO"?
                              <Button style={{width:'150px',fontSize:'10px',background:"#0350281A",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px"}} onClick={()=>setShowLayer(true)}>
                                <Box pad="small" direction="row" align="center" gap="medium">
                                    <Text size="small" color="#006837">CARICA DA FILE</Text>
                                </Box>
                              </Button>
                                :
                                null  
                              }
                              <Button width={dropdown!=="MACCHINARI"?'250px':'300px'} style={{fontSize:'10px',background:"#0350281A",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px"}} onClick={()=>mostraLayer()}>
                                <Box pad="small" direction="row" align="center" gap="medium">
                                    <Text size="small" color="#006837">INSERISCI NUOVO {getStringAnagrafica()}</Text>
                                </Box>
                              </Button>
                            </Box>
                            </>
                            :
                            null
                            }
                            {showLayer?
                              <LayerCaricatore/>
                             :
                              null}
                        </Box>
                       
                        { dropdown == 'PERSONALE' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerDip && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                         
                              <AddDip onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        	{ modifyPersonale ?
                              <>
                              <LayerGrommet onClickOutside={()=>setModifyPersonale(false)} onEsc={()=>setModifyPersonale(false)}>
                                <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                                  <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyPersonale(false)}/>}></Button>
                                  <AddDip dipendente={dipSel} onEscLayer={()=>setModifyPersonale(false)} onDelete={onDeleteDip}/>
                                </Box>
                              </LayerGrommet>
                              </>
                              :
                             null
                            }

                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <div style={{ height: '75vh', width: '100%' }}>
                              <DataGrid
                                rows={dipendenti}
                                columns={columnsDip}
                              />
                            </div>
                          </Box>
                        </>
                        :
                        null
                        }

                        { dropdown == 'LAVORI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerLavori && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
                              <AddLav onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyLavori?
                            <>
                            <LayerGrommet onClickOutside={()=>setModifyLavori(false)} onEscLayer={()=>setModifyLavori(false)}>
                              <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                                <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyLavori(false)}/>}></Button>
                                <AddLav lavoro={lavSel} onDelete={onDeleteLav} onEscLayer={()=>setModifyLavori(false)}/>
                              </Box>
                            </LayerGrommet>
                            </>
                            :
                            null
                          }
                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <div style={{ height: '75vh', width: '100%' }}>
                              <DataGrid
                                rows={lavori}
                                columns={columnsLav}
                              />
                            </div>
                          </Box>
                        </>
                        :
                        null
                        }

                        { dropdown == 'MACCHINARI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerMac && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
                              <AddMac onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyMacchinari ?
                          <>
                          <LayerGrommet onEscLayer={()=>setModifyMacchinari(false)} onClickOutside={()=>setModifyMacchinari(false)}>
                            <Box height="100%" pad="small" round="medium" background="#E5EDE9">				
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyMacchinari(false)}/>}></Button>
                              <AddMac macchina={macSel} onEscLayer={()=>setModifyMacchinari(false)} onDelete={onDeleteMac}/>
                            </Box>
                          </LayerGrommet>
                          </>
                          :
                            null
                        }
                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <div style={{ height: '75vh', width: '100%' }}>
                              <DataGrid
                                rows={macchine}
                                columns={columnsMac}
                              />
                            </div>
                          </Box>
                        </>
                        :
                        null
                        }

                        { dropdown == 'PRODOTTI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerProd && (
                          <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                          <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                            <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                  
                            <AddProd onEscLayer={onEscLayer}/>
                          </Box>
                        </LayerGrommet>
                          )
                        }
                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <Table>
                                <TableHeader style={{fontWeight:'bold', color:'#006837'}}>
                                  <TableRow>
                                    <TableCell scope="col" border="bottom">
                                      Nome Prodotto
                                    </TableCell>
                                      <TableCell scope="col" border="bottom">
                                        Produttore
                                      </TableCell>
                                      <TableCell scope="col" border="bottom">
                                        Quantità
                                      </TableCell>
                                      <TableCell scope="col" border="bottom">
                                        Unità di Misura
                                      </TableCell>
                                      <TableCell scope="col" border="bottom">
                                        Valore
                                      </TableCell>
                                      <TableCell scope="col" border="bottom">
                                        Sku
                                      </TableCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {prodotti.map(prodotto => (
                                    <AnagraficheProd prodotto={prodotto}/>
                                  ))}
                                </TableBody>
                            </Table>
                          </Box>
                        </>
                        :
                        null
                        }

                        { dropdown == 'CANTIERI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerCant && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                          
                              <AddArea onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyAree ?
                          <LayerGrommet onEscLayer={()=>setModifyAree(false)} onClickOutside={()=>setModifyAree(false)}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyAree(false)}/>}></Button>
                              <AddArea area={areaSel} onDelete={onDeleteArea} onEscLayer={()=>setModifyAree(false)}/>
                            </Box>
                          </LayerGrommet>
                            :
                          null
                        }
                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <div style={{ height: '75vh', width: '100%' }}>
                              <DataGrid
                                rows={areeLav}
                                columns={columnsCantieri}
                              />
                            </div>
                          </Box>
                           
                        </>
                        :
                        null
                        }

                        { dropdown == 'ACCESSORI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {/* END BUG #TK-31 FIX */}
                        {
                          showLayerAcc && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                       
                              <AddAccessorio onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                          <Box overflow="auto" width="100%" height="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <Table>
                                <TableHeader style={{fontWeight:'bold', color:'#006837'}}>
                                  <TableRow>
                                    <TableCell scope="col" border="bottom">
                                      Nome
                                    </TableCell>
                                    <TableCell scope="col" border="bottom">
                                      Matricola
                                    </TableCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {accessori.map(accessorio => (
                                    <AnagraficheAccessori accessorio={accessorio}/>
                                  ))}
                                </TableBody>
                            </Table>
                          </Box>
                        </>
                        :
                        null
                        }

                        { dropdown == 'RILIEVO' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerRil && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                         
                              <AddRil onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                         { modifyRilievo ?
                              <>
                              <LayerGrommet onClickOutside={()=>setModifyRilievo(false)}>
                                <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                                  <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyRilievo(false)}/>}></Button>
                                  <AddRil rilievo={rilSel} onEscLayer={()=>setModifyRilievo(false)} onDelete={onDeleteRil}/>
                                </Box>
                              </LayerGrommet>
                              </>
                              :
                             null
                            }
                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                          <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <div style={{ height: '75vh', width: '100%' }}>
                              <DataGrid
                                rows={rilievi}
                                columns={columnRil}
                              />
                            </div>
                          </Box>
                          </Box>
                        </>
                        :
                        null
                        }

                        { dropdown == 'ERRORI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerErr && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                          
                              <AddErr onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyErrore ?
                          <>
                          <LayerGrommet onClickOutside={()=>setModifyErrore(false)}>
                            <Box height="100%" round="medium" pad="15px" background="#E5EDE9">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyErrore(false)}/>}></Button>
                              <AddErr errore={errSel} onEscLayer={()=>setModifyErrore(false)} onDelete={onDeleteErrore}/>
                            </Box>
                          </LayerGrommet>
                          </>
                          :
                          null
                        }
                          <Box height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                            <div style={{ height: '75vh', width: '100%' }}>
                              <DataGrid
                                rows={errori}
                                columns={columnErr}
                              />
                            </div>
                          </Box>
                        </>
                        :
                        null
                        }
                      </Box>
                    </Box>
                </Box>
            </Box>
            :
            <iframe frameBorder="0" style={{width:"100%", height:"800px"}}  src="https://datastudio.google.com/embed/reporting/e93e6538-cbf7-44bd-b6f1-84bdd14c8d31/page/tX8sC"></iframe>
            }
        </Box>
        )}
         
      </ResponsiveContext.Consumer>
      
    </Grommet>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Box, DateInput, Text, Heading, Form, FormField, Select, TextInput, Button } from "grommet";
import firebase from '../firebase';


export default function AddLav(props) {
  
   
  /**
   * Funzione che aggiunge un anagrafica a database
   */
  const onAdd = () => {
    const db = firebase.firestore();
    db.collection('ManutenzioniEseguite').add({ CodiceManutenzione: newCodice, Data: newData, Telaio: newMacchina, OreMacchina: newOreMacchina});
    props.onEscLayer();
  }
  
  const [newData, setNewData] = useState("");
  const [newMacchina, setNewMacchina] = useState("");
  const [newCodice, setNewCodice] = useState("");
  const [newOreMacchina, setNewOreMacchina] = useState("");

  /*CHANGE DIPENDENTI*/

  const [TelaioMac, setTelaio] = useState([]);
  const [Intervento, setIntervento] = useState([]);

  const mac = firebase.firestore().collection('Macchinari');
  const int = firebase.firestore().collection('CodiciInterventi');

  /*
    Funzione che filtra i macchinari in basi al ruolo dell'utente loggato, al
    cantiere selezionato e se non sono 4.0 (canId=='').
    Saranno i macchinari mostrati nella Select
  */
  function getTelaio() {
    const role = window.sessionStorage.getItem('Role');
    const cantiere = window.sessionStorage.getItem('Cantiere');
    if (role == 'Admin') {
      mac.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setTelaio(items);
      });
    } else if (role == 'Admin_Cantiere') {
      mac.where('Cantiere', 'array-contains', cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setTelaio(items);
      });
    } else {
      mac.where('Zona', 'array-contains', cantiere).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setTelaio(items);
      });
    }
  }

  /*
    Funzione che trova tutti gli interventi di manutenzione presenti a sistema
  */
  function getIntervento() {
    int.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        
        setIntervento(items);
      });
  }

  /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset = () => {
    setNewCodice("");
    setNewData("");
    setNewMacchina("");
    setNewOreMacchina("");
  }


  useEffect(() => {
    getTelaio();
  }, []);

  useEffect(() => {
    getIntervento();
  }, []);


  return (
    <Box round pad="medium" direction="column" background="white" overflow="auto">
      <Heading level="2" margin="none" size="small">
        Inserisci intervento eseguito
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Codice Intervento">
              <Select
                multiple={false}
                closeOnChange={true}
                options={[... new Set(Intervento.sort(function(a, b) { return a.Codice.localeCompare(b.Codice) }).map(codici => (codici.Codice)))]}
                value={newCodice}
                onChange={event => 
                  setNewCodice(event.value)
                }
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Data Intervento Eseguito">
              <DateInput
                format="dd/mm/yyyy"
                value={newData}
                onChange={({ value }) => {setNewData(value.split('T')[0])}}
                calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
            />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Macchinario">
              <Select
                multiple={false}
                closeOnChange={true}
                options={[... new Set(TelaioMac.sort(function(a, b) { return a.NomeMacchina.localeCompare(b.NomeMacchina) }).map(macchinario => (macchinario.NomeMacchina+'-'+macchinario.Telaio)))]}
                value={newMacchina.value}
                onChange={event => 
                  setNewMacchina(event.value.split('-')[1])
                }
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Ore Macchina al momento dell'intervento">
              <TextInput
                placeholder="Inserire numero"
                onChange={e => setNewOreMacchina(e.target.value)}
              />
            </FormField>
          </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button label="Aggiungi" onClick={onAdd} style={{width:'138px',height:'34px',fontSize:'15px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button type="reset" onClick={reset} label="Cancella" style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
      </Box>
      </Box>
    </Box>
  )
}
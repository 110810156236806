import React from "react";
import {Box,Accordion,AccordionPanel,Text,Button} from "grommet";
import {Logout} from "grommet-icons"

/**
 * Implementa il componente di logout posto nella Home della web app.
 * @param {} props 
 * @returns 
 */
const AccountBox=(props)=>{
    
    const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]"));


    const cognome=props.name.split(' ')[1];

    return(
        <Box direction="row" gap="small">
            <Box width="50px" height="50px" background="#00683770" align="center" >
             <Box direction="row" pad="small">
                <Text size="large" weight="bold">{props.name.substring(0, 1)}</Text>
                {cognome!=undefined?
                <Text size="large" weight="bold">{(cognome!==undefined)?cognome.substring(0,1):""}</Text>
                :
                null
                }
            </Box>
            </Box>
            <Accordion>
                <AccordionPanel label={
                    <Box gap="5px">
                        <Text size="medium" weight="bold">{props.name}</Text>
                        <Text size="small">{userLogged.email}</Text>
                    </Box>
                }>
                    <Button margin="2%" style={{width:'200px',fontSize:'10px',background:'#006837',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>                 
                            <Button align="center" onClick={props.logout}>
                                <Box direction="row" gap="small" align="center" pad="small">
                                    <Logout color='white' />
                                    <Text size="medium" color='#FFFFFF'>Logout</Text>
                                </Box>
                            </Button>        
                    </Button>
                </AccordionPanel>
            </Accordion>
        </Box>

    )
}

export default AccountBox;
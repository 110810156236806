import React, { useState, useEffect, useRef } from "react";
import { Box, Menu, Grid, Grommet, Text, Card, CardHeader, CardBody, Image, Select, CardFooter, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Notification as NotificationApp } from 'grommet';
import { FormClose, Notification, Home, CircleInformation, Add, Favorite, ShareOption, Edit, LinkPrevious, UserWorker, MapLocation, Analytics, Task, Configure, Location, Trash } from "grommet-icons";
import Notifications from "./Notifications";
import AddAtt from "./AddAtt";
import AnagraficheAtt from "./AnagraficheAtt";
import firebase from '../firebase';
import MapBuche from "./MapBuche";
import  { Redirect } from 'react-router-dom';
import { ScrollMenu, } from "react-horizontal-scrolling-menu";

const theme = {
  global: {
    colors: {
      brand: '#006837',
      'accent-1': 'white',
      'focus': '#006837',
      "my-text-color": "",
      background: '#0350281A',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};


const App = (props) => {
  const buc = firebase.firestore().collection('Coordinate');
  const [buche, setBuche] = useState([]);
  const cantiere = sessionStorage.getItem('Cantiere');
  const [adlFilter, setAdlFilter] = useState([]);
  const [key, setKey] = useState(0);
  const [TotArea, setTotArea] = useState("Tutte");
  const [show, setShow] = useState(false);

  /**
   * Funzione che rileva tutti i tracciamenti delle buche in base al ruolo dell'utente loggato e al cantiere/zona selezionto/a 
   * e applica (se ci sono) i filtri per Adl
   * Calcola inoltre l'area totale per ogni buca.
   * 
   */
  function getBuche() {
    if (dropdownAdlFilter == "") 
    {
      if (sessionStorage.getItem('Role') == 'Admin_Zona') {
        buc.where('ZonaFilter', '==', cantiere).onSnapshot((querySnapshot) => {
          const items = [];
          const labels = [];
          let areaTotale = 0;
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          //setBuche(items);
          
          items.forEach(item => {
            for (var i=0; i<item.Coords.length;i++) {
              labels.push(item.Coords[i].label);
            }
            if (item.Tipologia == "Rough" || item.Tipologia == "Altro" || item.Tipologia=="Campo Pratica") {
              areaTotale += Number(item.TotArea);
              
            }
          });
          labels.push("");
          setTotArea(Math.round(areaTotale));
          setAdlFilter(labels);
          setKey("Tutte");
        });
      } else if (sessionStorage.getItem('Role') == 'Admin_Cantiere') {
        buc.where('Cantiere', '==', cantiere).onSnapshot((querySnapshot) => {
          const items = [];
          const labels = [];
          let areaTotale = 0;
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setBuche(items);
          
          items.forEach(item => {
            for (var i=0; i<item.Coords.length;i++) {
              labels.push(item.Coords[i].label);
            }
            if (item.Tipologia == "Rough" || item.Tipologia == "Altro" || item.Tipologia=="Campo Pratica") {
              areaTotale += Number(item.TotArea);
              
            }
          });
          labels.push("");
          setTotArea(Math.round(areaTotale));
          setAdlFilter(labels);
          setKey("Tutte");
        });
      } else {
        buc.onSnapshot((querySnapshot) => {
          const items = [];
          const labels = [];
          let areaTotale = 0;
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          setBuche(items);
          
          items.forEach(item => {
            for (var i=0; i<item.Coords.length;i++) {
              labels.push(item.Coords[i].label);
            }
            if (item.Tipologia == "Rough" || item.Tipologia == "Altro"|| item.Tipologia=="Campo Pratica") {
              areaTotale += Number(item.TotArea);
              
            }
          });
          labels.push("");
          setTotArea(Math.round(areaTotale));
          setAdlFilter(labels);
          setKey("Tutte");
        });
      }
    } else {
      if (sessionStorage.getItem('Role') == 'Admin_Zona') {
        buc.where('ZonaFilter', '==', cantiere).onSnapshot((querySnapshot) => {
          const items = [];
          const itemsFiltered = [];
          const labels = [];
          let areaTotale = 0;
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          //setBuche(items);
          
          items.forEach(item => {
            for (var i=0; i<item.Coords.length;i++) {
              labels.push(item.Coords[i].label);
              if (item.Coords[i].label == dropdownAdlFilter)
              {
                itemsFiltered.push({Buca: item.Buca, Cantiere: item.Cantiere, Colore: item.Colore, Coords: item.Coords[i], Nome: item.Nome, Tipologia: item.Tipologia,
                                    TotArea: item.TotArea, Tracciato: item.Tracciato, ZonaFilter: item.ZonaFilter});
                if (item.Tipologia == "Rough" || item.Tipologia == "Altro" || item.Tipologia=="Campo Pratica") {
                  areaTotale += Number(item.Coords[i].area);
                  
                }
                setKey(item.Coords[i].label);
              }
            }
          });
          labels.push("");
          
          setBuche(itemsFiltered);
          setTotArea(Math.round(areaTotale));
          setAdlFilter(labels);
        });
      } else if (sessionStorage.getItem('Role') == 'Admin_Cantiere') {
        buc.where('Cantiere', '==', cantiere).onSnapshot((querySnapshot) => {
          const items = [];
          const itemsFiltered = [];
          const labels = [];
          let areaTotale = 0;
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          //setBuche(items);
          
          items.forEach(item => {
            for (var i=0; i<item.Coords.length;i++) {
              labels.push(item.Coords[i].label);         
              if (item.Coords[i].label == dropdownAdlFilter)
              {
                itemsFiltered.push({Buca: item.Buca, Cantiere: item.Cantiere, Colore: item.Colore, Coords: item.Coords[i], Nome: item.Nome, Tipologia: item.Tipologia,
                                    TotArea: item.TotArea, Tracciato: item.Tracciato, ZonaFilter: item.ZonaFilter});
                if (item.Tipologia == "Rough" || item.Tipologia == "Altro" || item.Tipologia=="Campo Pratica") {
                  areaTotale += Number(item.Coords[i].area);
                  
                }
                setKey(item.Coords[i].label);
              }
            }
          });
          labels.push("");
          
          setBuche(itemsFiltered);
          setTotArea(Math.round(areaTotale));
          setAdlFilter(labels);
        });
      } else {
        buc.onSnapshot((querySnapshot) => {
          const items = [];
          const itemsFiltered = [];
          const labels = [];
          let areaTotale = 0;
          querySnapshot.forEach((doc) => {
            items.push( { ... doc.data(), id: doc.id } );
          });
          //setBuche(items);
          
          items.forEach(item => {
            for (var i=0; i<item.Coords.length;i++) {
              labels.push(item.Coords[i].label);
              if (item.Coords[i].label == dropdownAdlFilter)
              {
                itemsFiltered.push({Buca: item.Buca, Cantiere: item.Cantiere, Colore: item.Colore, Coords: item.Coords[i], Nome: item.Nome, Tipologia: item.Tipologia,
                                    TotArea: item.TotArea, Tracciato: item.Tracciato, ZonaFilter: item.ZonaFilter});
                if (item.Tipologia == "Rough" || item.Tipologia == "Altro" || item.Tipologia=="Campo Pratica") {
                  areaTotale += Number(item.Coords[i].area);
                  
                }
                setKey(item.Coords[i].label);
              }
            }
          });
          labels.push("");
          
          setBuche(itemsFiltered);
          setTotArea(Math.round(areaTotale));
          setAdlFilter(labels);
        });
      }
    }

  }

  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const role = firebase.firestore().collection('Ruoli');
  /*END CHANGE IMPLEMENTAZIONE RUOLI*/

  const [bucaFilter, setBucaFilter] = useState(sessionStorage.getItem('BucaFilter') === null ? "" : sessionStorage.getItem('BucaFilter'));
  const [dropdownAdlFilter, setDropdownAdlFilter] = useState("");

  /**
   * Funzione che rileva il ruolo dell'utente loggato
   */
  function getRuoli() {
      role.where('Utente', '==', userLogged.email).onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push( { ... doc.data(), id: doc.id } );
        });
        setRuoli(items);
        
      });
  }

  /**
   * Funzione che cancella l'intero tracciamento
   */
  function deleteBuca(id) {
    const db = firebase.firestore();
    const ask = window.confirm("SEI SICURO DI VOLER ELIMINARE LA TRACCIA?");
    if (ask == true) {
      db.collection('Coordinate').doc(id).delete().then(response => {
        window.location.reload();
        }
      );
    } else {
      alert("OPERAZIONE ANNULLATA!");
    }
  }

  useEffect(() => {
    getRuoli();
  }, []);

  useEffect(() => {
    getBuche();
  }, [dropdownAdlFilter]);

  if (sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]") == null) {
    return <Redirect to='/'  />
  }

  const userLogged = JSON.parse(sessionStorage.getItem("firebase:authUser:AIzaSyD_5g7gBayx_Q5e8A_CAiOTwkgMhX8FI6Q:[DEFAULT]"));
  /**
   * IMPORTANTE: il componente si comporta in modo diverso in base all'utenza di login
   * Admin generale: Mostra le card contenti tutti i tracciati
   * Admin cantiere/zona: Mostra una mappa con i tracciati che rigurdano solo il cantiere/zona dell'utente loggato
   */
  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box fill pad="small">
            <Box direction="row" >
            <Box width="100%" height="94vh" direction='row' flex="false" overflow={{ horizontal: 'hidden' }}>
                <Box flex >
                  <Box direction='row'  flex="false" overflow={{ horizontal: 'hidden' }} alignSelf="center">
                    {ruoli[0] !== undefined ?
                      (ruoli[0].Ruolo == 'Admin' ?
                      
                        <Box direction='row' gap="small" align="center">
                            <Text margin="small" color="#04671E" size="large" weight="bold">SELEZIONA ZONA </Text>
                            <Select
                              placeholder="Seleziona Zona"
                              value={bucaFilter}
                              options={[... new Set(buche.sort(function(a, b) { return a.Nome.localeCompare(b.Nome) }).map(buca => (buca.ZonaFilter)))]}
                              onChange={event => {setBucaFilter(event.target.value); sessionStorage.setItem('BucaFilter', event.target.value)}}  
                            />         
                        </Box>
                        
                      :
                    null
                      )
                    :
                      null
                    }
                  </Box>
                  {
                 ruoli[0]!==undefined?
                  (ruoli[0].Ruolo=='Admin_Cantiere' || ruoli[0].Ruolo=='Admin_Zona')?   
                   (buche.length>0)?
                   <>
                    <Box direction="row" alignSelf="center" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: "2%"}} gap="small">
                        <Text size="medium" color="#006837" alignSelf="center">
                          Area (mq): {TotArea}
                        </Text>
                        <Select
                          placeholder="Filtro Area"
                          value={dropdownAdlFilter}
                          options={[... new Set(adlFilter.map(label => (label)))]}
                          onChange={event => setDropdownAdlFilter(event.target.value)}
                        />
                          <>
                      <Button label="Aree Det." onClick={() => setShow(true)} style={{width:'217px',fontSize:'15px',background:"#006837",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px", borderRadius:"10px"}} />
                      {show && (
                        <Layer
                          onEsc={() => setShow(false)}
                          onClickOutside={() => setShow(false)}
                        >
                          <Box pad="small" overflow="scroll" width="500px" height="470px" align="center">
                          <>
                            {buche.map(buca => (
                              <Text style={{fontSize:'28px', fontWeight:'bold', color: buca.Colore, webkitTextStrokeWidth: '0.0001em', webkitTextStrokeColor: '#808080' }}> 
                                Area {buca.Nome} <br></br>
                                  {buca.Coords.length === undefined ? 
                                      <Box align="center">
                                        <Text style={{fontSize:'20px'}}> {buca.Coords.label}(mq) : {Math.round(buca.Coords.area)} <br></br> </Text>
                                      </Box>
                                    :
                                      buca.Coords.map(areaSingle => (
                                        <Box align="center">
                                          <Text style={{fontSize:'20px'}}> {areaSingle.label}(mq) : {Math.round(areaSingle.area)} <br></br> </Text>
                                        </Box>
                                      ))
                                  }
                                <br></br>
                              </Text>
                            ))}
                            <Button label="chiudi" onClick={() => setShow(false)} style={{fontSize:'28px', background:'#006837', color:'white', fontWeight:'bold', textTransform: 'uppercase'}} />
                          </>
                          </Box>
                        </Layer>
                        )}
                      </>
                    </Box>
                    <Box style={{marginTop: '1%', height: '700px', position: 'relative'}} direction="row">
                      <MapBuche key={key} admin={false} buche={buche} />
                    </Box>
                    </>
                    :
                    <Text alignSelf="center" weight="bold">Non sono presenti buche</Text>
                  :
                  <Box height="550px" gap="medium" pad="medium">
                    <ScrollMenu>
                    {buche.map(buca => (
                      bucaFilter == "" ?
                        <Box width="400px" >
                        <Card margin="small" background="light-1">
                          <CardHeader pad="medium">
                            <Text style={{fontWeight:'bold'}}>{buca.Buca}</Text>
                            <Button style={{marginRight: '0px'}} label="" size="medium" icon={<Trash color="brand"/>} onClick={() => deleteBuca(buca.id)}></Button>
                            <Button style={{marginRight: '0px'}} label="" size="medium" icon={<Edit color="brand"/>} onClick={() => window.location.href = window.location.origin + "/modificaTraccia/" + buca.id}></Button>
                          </CardHeader>
                          <CardBody pad="medium">
                            Area Buca (mq): {Math.round(buca.TotArea)} <br/><br/>
                            Area di Lavoro: {buca.Tipologia} <br/><br/>
                            Tracciamento GPS: {buca.Tracciato} <br/><br/>
                          </CardBody>
                          <CardFooter height="medium" pad={{horizontal: "small"}} background="light-2" style={{position: 'relative'}}>  
                            <MapBuche admin={true} foo={buca.Buca} zone={buca.Tipologia} cantiere={buca.Cantiere} filter={buca.ZonaFilter}/>
                          </CardFooter>
                        </Card>
                        </Box>
                      :
                      
                        buca.ZonaFilter == bucaFilter ?
                          <Box width="400px">
                          <Card margin="small" background="light-1" >
                            <CardHeader pad="medium">
                              <Text style={{fontWeight:'bold'}}>{buca.Buca}</Text>
                              <Button style={{marginRight: '0px'}} label="" size="medium" icon={<Trash color="brand"/>} onClick={() => deleteBuca(buca.id)}></Button>
                              <Button style={{marginRight: '0px'}} label="" size="medium" icon={<Edit color="brand"/>} onClick={() => window.location.href = window.location.origin + "/modificaTraccia/" + buca.id}></Button>
                            </CardHeader>
                            <CardBody pad="medium">
                              Area Buca (mq): {Math.round(buca.TotArea)} <br/><br/>
                              Area di Lavoro: {buca.Tipologia} <br/><br/>
                              Tracciamento GPS: {buca.Tracciato} <br/><br/>
                            </CardBody>
                            <CardFooter height="medium" pad={{horizontal: "small"}} background="light-2" style={{position: 'relative'}}>  
                              <MapBuche admin={true} foo={buca.Buca} zone={buca.Tipologia} cantiere={buca.Cantiere} filter={buca.ZonaFilter}/>
                            </CardFooter>
                          </Card>
                          </Box>
                        :
                          null
                    ))}
                    </ScrollMenu>
                    <Button style={{width:'217px',fontSize:'15px',background:"#006837",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#006837',borderWidth:"3px", borderRadius:"10px"}} onClick={()=>window.location.href = window.location.origin + "/tracciaAdL"}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Location color="white"/>
                          <Text size="small" color="white">Traccia ADL</Text>
                      </Box>
                    </Button>
                  </Box>
                    :
                    null
                  }
                </Box>
                </Box>
            </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from './App';
import Anagrafiche from "./components/Anagrafiche";
import Attivita from "./components/Attivita";
import AttivitaDet1 from "./components/AttivitaDet1";
import AttivitaDet from "./components/AttivitaDet";
import BucheDet from "./components/BucheDet";
import Dashboard from "./components/Dashboard";
import TracciaCampo from "./components/TracciaCampo";
import Manutenzione from "./components/Manutenzione";
import reportWebVitals from './reportWebVitals';
import LogIn from "./components/LogIn";
import Notification from "./components/Notifiche";

ReactDOM.render(
    
  <React.StrictMode>
  	<Router>
        <Route exact path="/">
            <LogIn />
        </Route>
        <Route path="/home">
            <App />
        </Route>
        <Route path="/anagrafiche">
            <Anagrafiche />
        </Route>
        <Route path="/attivita">
            <AttivitaDet1 />
        </Route>
        <Route path="/attivitaDettaglio">
            <Attivita />
        </Route>
        <Route path="/dashboard">
            <Dashboard />
        </Route>
        <Route path="/dettaglio/:id" render = { props => <AttivitaDet {...props} /> } >
        </Route>
        <Route path="/bucheDettaglio">
            <BucheDet />
        </Route>
        <Route path="/manutenzioneMacchinari">
            <Manutenzione />
        </Route>
        <Route path="/tracciaAdL">
            <TracciaCampo />
        </Route>
        <Route path="/modificaTraccia/:id" render = { props => <TracciaCampo {...props} /> } >
        </Route>
        <Route path="/notifiche" render = { props => <Notification {...props} /> } >
        </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
